import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import useInvoiceApi from '../API/invoice.api'
import usePage from '../hooks/page.hook'

import * as filterSelectors from '../redux/selectors/filter.selectors'

import styles from '../styles/pages/invoices.module.css'
import Invoice from '../components/Invoice'
import InvoiceFilter from '../components/InvoiceFilter'


function Invoices() {
  const invoiceApi = useInvoiceApi()

  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.invoiceTriger)

  const [invoices, setInvoices] = useState([])
  
  const load = async (page) => {
    console.log('load page - ', page);
    const {list, count} = await invoiceApi.list(page, pagination.limit)

    pagination.setCount(count)
    setInvoices(list)
  }

  useEffect(() => {
    const timer = setInterval(() => load(page), 10000)
    load(page)

    return () => { clearInterval(timer) }
  }, [page, triger])

  return (
    <div>
        <div className={styles.main}>
          <div className={styles.top}>
            <InvoiceFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card</div>
              <div>ID</div>              
              <div className={styles.status}>Status</div>
              <div className={styles.status}>Action</div>
              <div className={styles.date}>time</div>
          </div>

          <div className={styles.table}>
              {invoices.map((invoice) => <Invoice invoice={invoice} refresh={() => load(page)} key={invoice._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}


export default Invoices