import { LOAD, CLEAR, REFRESH } from '../types/partners.types'


export function loadNames(partners) {   
    return {
        type: LOAD,
        payload: { list: partners }
    }
}

export function load(partners, waitBalance) {       
    return {
        type: LOAD,
        payload: { 
            list: partners.map((partner) => {
                const item = {...partner}           
                item.waitTotal = waitBalance[partner._id]? waitBalance[partner._id] : 0
                return item
            })
        } 
    }
}

export function refresh(partner) {
    return {
        type: REFRESH,
        payload: partner
    }
}

export function clear() {
    return {
        type: CLEAR
    }
}
