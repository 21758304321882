import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../styles/components/card.module.css'
import { formatTime } from '../utils/time.utils'
import * as partnersSelectors from '../redux/selectors/partners.selectors'
import useCards from '../hooks/cards.hook'
import useCardApi from '../API/card.api'
import Select from './UI/Select'
import { Link } from 'react-router-dom'
import Input from './UI/Input'
import useInput from '../hooks/input.hook'
import {check} from './source/Icons.source'


function Card({card, conversion}) {
  const { turn, turnWatch, remove, load, setPartner } = useCards()

  const partners = useSelector(partnersSelectors.filter())
  const partnersOption = partners.map((partner) => ({ value: partner._id, label: partner.name }))
  const priority = useInput(card.priority)
  const Card = useCardApi()


  const selectHandler = async (option) => { setPartner(card._id, option.value) }

  const setPriority = async () => { 
    await Card.setPriority(card._id, priority.value) 
    await load()
  }

  const partnerSelect = <Select option={partnersOption} value={card.partner} handler={selectHandler} placeholder={'Select partner'} />

  const cardStatus = card?.isLim? 'Blocked' : card?.active? 'Active' : 'Inactive'
  const cardStatusStyle = (card?.isLim || !card?.active)? styles.inactive : styles.active

  return (
    <div className={styles.card}>
        <Link className={`${styles.number}`} to={`/card/${card?._id}`}>{card?.number}</Link>
        <div>{card?.description}</div>
        <div>
          {card?.limits?.min}/{card?.limits?.max}
          <br/>
          {card?.count}/{card?.limits?.count}
          <br/>
          {parseInt(card?.total)}/{card?.limits?.total}
        </div>
        <div className={styles.select}>{partnerSelect}</div>
        <div>
          <Input input={priority} className={styles.input} />
          <button onClick={() => setPriority()} className={styles.priorityBtn}>{check}</button>
        </div>
        <div>
            <div className={`${styles.status} ${cardStatusStyle}`}>
              {cardStatus}
            </div>
        </div>
        <div className={styles.actions}>
            <div className={`${styles.button} ${card?.active? styles.off : styles.on}`} onClick={() => turn(card?._id)}>
              {card?.active? 'off' : 'on'}
            </div>

            {card?.isJar && <>
              <div className={`${styles.button} ${card?.jarMonit? styles.on : styles.off}`} onClick={() => turnWatch(card?._id)}>
                👁
              </div>
            </>}

            {card?.jarId && <>
              <div className={`${styles.button} ${styles.on}`}>sync</div>
            </>}

            <div className={`${styles.button}`} onClick={() => remove(card?._id)}>
              del
            </div>
        </div>
        <div className={styles.conv}>
          {(conversion && conversion.all[card?._id]) && <div>
            <span style={{color: '#AAD6A9'}}>{conversion.all[card._id].countConfirm}/</span>
            <span style={{color: '#E25D4A'}}>{conversion.all[card._id].count}</span>
            <span>({parseInt(100 * conversion.all[card._id].conversion)}%)</span>
          </div>} 
          {(conversion && conversion.temp[card?._id]) && <div>
            <span style={{color: '#AAD6A9'}}>{conversion.temp[card._id].countConfirm}/</span>
            <span style={{color: '#E25D4A'}}>{conversion.temp[card._id].count}</span>
            <span>({parseInt(100 * conversion.temp[card._id].conversion)}%)</span>
          </div>} 
        </div>
        <div className={styles.date}>
          {formatTime(card?.createdAt)}
        </div>
    </div>
  )
}

export default Card