import React from 'react'
import Input from './UI/Input'
import useInput from '../hooks/input.hook'
import styles from '../styles/components/range.module.css'


function Range({range}) {
    return (
        <div className={styles.main}>
            <Input input={range.start} placeholder='Start Timestamp' />
            <Input input={range.stop} placeholder='Stop Timestamp' />
        </div>
    )
}

export default Range