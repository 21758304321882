import { useDispatch } from 'react-redux'
import { load } from '../redux/actions/user.actions'
import useWorkerApi from '../API/worker.api'


export default function useWorker() {
    const workerApi = useWorkerApi()
    const dispatch = useDispatch()
    
    const update = async () => {
        const balance = await workerApi.getBalance()
        const waitBalance = await workerApi.getWaitBalance()

        const paymentActive = await workerApi.getOutActive()
        const ibanActive = await workerApi.getIbanActive()

        dispatch(load({ balance, waitBalance, paymentActive, ibanActive }))
    }

    return { 
        update
    }
}