import React from 'react'
import styles from '../../styles/components/admin/partner.module.css'
import { Link } from 'react-router-dom'


function Partner({partner}) {  
  return (
    <div className={styles.card}>
        <div><Link to={`/partner/${partner?._id}`}>{partner?.name}</Link></div>
        <div>{partner?._id}</div>
        <div className={styles.date}>
          {partner?.balance?.curent}
          <br />
          ({partner?.waitTotal})
        </div>
    </div>
  )
}

export default Partner