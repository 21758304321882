import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useWithdrawApi from '../../API/withdraw.api'
import useAdminApi from '../../API/admin.api'
import usePage from '../../hooks/page.hook'

import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/withdraws.module.css'
import Withdraw from '../../components/Admin/Withdraw'
import WithdrawFilter from '../../components/Admin/WithdrawFilter'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'

function Withdraws() {
  const adminApi = useAdminApi()
  const withdrawApi = useWithdrawApi()
  
  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.withdrawTriger)

  const [invoices, setInvoices] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)

  const range = useRange()

  const downloadReport = async () => { 
    const { start, stop } = range.value
    const url = await adminApi.withdrawReport(start, stop, isUpdate)
    window.open(url) 
  } 

  const load = async () => {
    const {list, count} = await withdrawApi.listAll(page, pagination.limit)

    pagination.setCount(count)
    setInvoices(list)
  }

  useEffect(() => { load() }, [page, triger])

  return (
    <div>
      <div className="d-flex">
        <Range range={range.bind} />
        <div>
          <input type="checkbox" checked={isUpdate} onChange={() => setIsUpdate((prev) => !prev)}/>
          <span>Udate At</span>
        </div>
        <button className='report' onClick={downloadReport}>Report</button>
      </div>

      <div className={styles.main}>
          <div className={styles.top}>
            <WithdrawFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Course</div>
              <div>Worker</div>
              <div>Partner</div>
              <div>ID</div>              
              <div className={styles.date}>time</div>
          </div>

          <div className={styles.table}>
              {invoices.map((invoice) => <Withdraw withdraw={invoice} key={invoice._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}


export default Withdraws