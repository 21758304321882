export const list = (state) => state.workers.list
export const filter = () => { 
    return (state) => {
        const {id} = state.filter.workers

        let res = state.workers.list 

        if(id) { res = res.filter((worker) => worker._id === id) }

        return res
    }
}
export const options = (state) => {
    const workers = state.workers.list 

    const options = workers.map((worker) => ({ value: worker._id, label: worker.login }))
    options.push({ value: undefined, label: 'All' })

    return options
}
export const triger = (state) => state.workers.triger
