import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Pool from '../pages/Admin/Pool.page'
import Invoices from '../pages/Admin/Invoices.page'
import Payments from '../pages/Admin/Payments.page'
import Unknows from '../pages/Admin/Unknows.page'
import Partners from '../pages/Admin/Partners.page'
import Workers from '../pages/Admin/Workers.page'
import Worker from '../pages/Admin/Worker.page'
import Partner from '../pages/Admin/Partner.page'
import Statistics from '../pages/Admin/Statistics.page'
import Withdraws from '../pages/Admin/Withdraw.page'
import Swap from '../pages/Admin/Swap.page'
import With from '../pages/Admin/With.page'
import CreatePayment from '../pages/Admin/CreatePayment.page'
import CreateIbanPayment from '../pages/Admin/CreateIbanPayment.page'


const adminRoutes = (
    <Routes>
        <Route path="/" element={<Statistics />} exact />
        <Route path="/invoices" element={<Invoices />} exact />
        <Route path="/payments" element={<Payments />} exact />
        <Route path="/unknows" element={<Unknows />} exact />
        <Route path="/withdraws" element={<Withdraws />} exact />

        <Route path="/swap" element={<Swap />} exact />
        <Route path="/withdraw" element={<With />} exact />
        <Route path="/create-payment" element={<CreatePayment />} exact />
        <Route path="/create-iban-payment" element={<CreateIbanPayment />} exact />

        <Route path="/pool" element={<Pool />} exact />
        <Route path="/partners" element={<Partners />} exact />
        <Route path="/workers" element={<Workers />} exact />

        <Route path="/worker/:id" element={<Worker />} exact />
        <Route path="/partner/:id" element={<Partner />} exact />

        <Route path="*" element={<Statistics />} exact />
    </Routes>
)     

export default adminRoutes