import React, { useState } from 'react'
import * as icons from './source/Icons.source'


function Filter({ option }) {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState(option.map((item) => ({ ...item, open: false })))

    const toggle = (index) => {
        setOptions((prev) => {
            const temp = [...prev]
            temp[index].open = (!prev[index].open)
            return temp
        })
    }

    return (
        <div className='filter'>
            <div className='filter__button' onClick={() => setOpen((prev) => !prev)}>Filter</div>
            <div className={`filter__option ${open? 'active' : null}`}>
                <div className='filter__top'>Filter</div>
                {options.map((item, key) => (
                    <div className={`filter__row ${item.open? 'active-row' : null}`} key={key}>
                        <div className='filter__title' onClick={() => toggle(key)}>
                            <div className='filter__label'>{item.title}</div>
                            <div className='filter__icon'>{icons.shevronRight}</div>
                        </div>
                        <div className='filter__content'>
                            {item.elem}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Filter