import { useState } from 'react'
import Chart from 'chart.js/auto'


export default function useChart(canvas, type='line', options={}) {
    const [chart, setChart] = useState(null)
        
    const setData = (labels, datasets) => {
        if(!chart) { return setChart(new Chart(canvas.current, {type, data: {labels, datasets}, options})) }
        else {
            chart.data.labels = labels
            chart.data.datasets = datasets
            chart.update()
        }
    }

    return { 
        chart,
        setChart,
        setData
    }
}