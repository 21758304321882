import React, { useState } from 'react'
import styles from '../styles/pages/unknow.module.css'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../components/UI/Input'
import Select from '../components/UI/Select'
import useInput from '../hooks/input.hook'
import { useSelector } from 'react-redux'
import * as cardsSelectors from '../redux/selectors/cards.selectors'
import useInvoiceApi from '../API/invoice.api'
import useAlert from '../hooks/alert.hook'



function Unknow() {
  const navigate = useNavigate() 
  const invoiceApi = useInvoiceApi()
  const { pushMess } = useAlert()

  const [card, setCard] = useState(null)

  const amount = useInput('')
  const time = useInput('')
  const description = useInput('')

  const cards = useSelector(cardsSelectors.list) || []
  const cardsOption = cards.map((card) => ({ value: card._id, label: card.number }))

  const handler = async () => {
    const res = await invoiceApi.unknow(card, amount.value, time.value, description.value)
    if(!res) { return }

    pushMess('Successeful')
    navigate('/unknows')
  }

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.label}>Card</div>
        <Select option={cardsOption} handler={(option) => { setCard(option.value) }} placeholder={'Select card'} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Amount</div>
        <Input input={amount} placeholder='Amount' />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Time</div>
        <Input input={time} placeholder='Time' />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Description</div>
        <Input input={description} placeholder='Description' />
      </div>

      <div className={styles.buttons}>
        <button className={`${styles.button} ${styles.button_main}`} onClick={() => handler()}>Push</button>
        <Link className={styles.button} to={'/unknows'}>Cancel</Link>
      </div>
    </div>
  )
}

export default Unknow