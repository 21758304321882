import { useDispatch } from 'react-redux'

import * as workers from '../redux/actions/workers.actions'
import useWorkerApi from '../API/worker.api'
import useAdminApi from '../API/admin.api'


export default function useWorkers() {
    const workerApi = useWorkerApi()
    const adminApi = useAdminApi()

    const dispath = useDispatch()

    const load = async () => { dispath(workers.load(await workerApi.list(), await adminApi.getWorkersWaitBalance())) }
    const refresh = async (id) => { dispath(workers.refresh(await workerApi.get(id))) }
    const clear = () => { dispath(workers.clear()) }

    return { 
        load, 
        refresh,
        clear
    }
}