import React from 'react'
import Input from '../../components/UI/Input'
import useInput from '../../hooks/input.hook'
import useAdminApi from '../../API/admin.api'
import useAlert from '../../hooks/alert.hook'

import styles from '../../styles/pages/createPayment.module.css'


function CreateIbanPayment() {
    const adminApi = useAdminApi()
    const Alert = useAlert()

    const amount = useInput(0)
    const card = useInput('')
    const iban = useInput('')
    const inn = useInput('')
    const recipient = useInput('')


    const createHandler = async () => {
        const cardNumber = card.value? card.value : null

        const res = await adminApi.createPayment(amount.value, cardNumber, {
            number: iban.value,
            INN: inn.value,
            recipient: recipient.value
        })
        if(res) { Alert.pushMess('Payment has bin created') }
    }

    return (
        <div className={styles.main}>
            <div className={styles.form}>
                <Input input={card} placeholder='Card Number'/>
                <Input input={amount} placeholder='Amount' />
                <Input input={iban} placeholder='Iban'/>
                <Input input={inn} placeholder='INN'/>
                <Input input={recipient} placeholder='recipient'/>

                <button onClick={() => createHandler()} className={styles.button}>Create</button>
            </div>
        </div>
    )
}

export default CreateIbanPayment