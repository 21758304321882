import React from 'react'

import styles from '../styles/components/mono.module.css'
import useMonoApi from '../API/mono.api'

function Mono({mono, reload, reportHandler}) {
    const monoApi = useMonoApi() 


    const syncHandler = async () => {
        await monoApi.sync(mono._id)
        reload()
    }

    const removeHandler = async () => {
        await monoApi.remove(mono._id)
        reload()
    }

    return (
        <div className={styles.main}>
            <div>{mono?.description}</div>
            <div>{mono?._id}</div>
            <div>
                <button onClick={() => removeHandler()} className={`${styles.button}`}>Del</button>
                <button onClick={() => reportHandler(mono?._id)} className={`${styles.button}`}>Report</button>
                <button onClick={() => syncHandler()} className={`${styles.button} ${styles.on}`}>Sync</button>
            </div>
            <div>
                {mono?.monit
                    ? <div className={`${styles.status} ${styles.on}`}>Connected</div>
                    : <div className={`${styles.status} ${styles.off}`}>Disconnected</div>
                }
            </div>
        </div>
      )
}

export default Mono