import React, { useEffect, useState } from 'react'
import usePaymentApi from '../API/payment.api'
import usePage from '../hooks/page.hook'
import { useSelector } from 'react-redux'

import * as filterSelectors from '../redux/selectors/filter.selectors'
import * as userSelectors from '../redux/selectors/user.selectors'
import styles from '../styles/pages/payments.module.css'
import Payment from '../components/Payment'
import PaymentFilter from '../components/PaymentFilter'
import useWorkerApi from '../API/worker.api'
import useWorker from '../hooks/worker.hook'


function Payments() {
  const paymentApi = usePaymentApi()
  const Worker = useWorkerApi()

  const { update } = useWorker()

  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.paymentTriger)
  const isPaymentActive = useSelector(userSelectors.paymentActive)
  const isIbanActive = useSelector(userSelectors.ibanActive)

  const [payments, setPayments] = useState([])

  const load = async (page) => {
    console.log('load page - ', page);
    const {list, count} = await paymentApi.list(page, pagination.limit)

    pagination.setCount(count)
    setPayments(list)
  }

  useEffect(() => {
    const timer = setInterval(() => load(page), 10000)
    load(page)

    return () => { clearInterval(timer) }
  }, [page, triger])


  const togglePaymentHandler = async () => {
    await Worker.toggleOutActive()
    update()
  }

  const toggleIbanHandler = async () => {   
    await Worker.toggleIban()
    update()
  }


  return (
    <div>
        <button className={`${styles.button} ${isPaymentActive? styles.off : styles.on}`} onClick={() => togglePaymentHandler()}>
            {isPaymentActive? 'off' : 'on'}
        </button>
        <button className={`${styles.button} ${isIbanActive? styles.off : styles.on}`} onClick={() => toggleIbanHandler()}>
            {isIbanActive? 'off Iban' : 'on Iban'}
        </button>
        <br />
        <br />
        <div className={styles.main}>
          
          <div className={styles.top}>
            <PaymentFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card/Iban</div>
              <div>ID</div>
              <div className={styles.status}>Status</div>
              <div className={styles.status}>Action</div>
              <div className={styles.date}>Created</div>
          </div>

          <div className={styles.table}>
              {payments.map((payment) => <Payment payment={payment} refresh={() => load(page)} key={payment._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
        </div>
    </div>
  )
}

export default Payments