import React from 'react'
import styles from '../styles/pages/settings.module.css'
import Input from '../components/UI/Input'
import useInput from '../hooks/input.hook'
import useWorkerApi from '../API/worker.api'

function Settings () {
    const workerApi = useWorkerApi()

    const password = useInput('')
    const telegram = useInput('')
    const group = useInput('')
    const proof = useInput('')
    const jar = useInput('')
    const out = useInput('')

    const passwordHandler = async () => {
        const res = await workerApi.changePassword(password.value)
        if(res) { password.clear() }
    }

    const telegramHandler = async () => {
        const res = await workerApi.changeTelegram(telegram.value)
        if(res) { telegram.clear() }
    }

    const groupHandler = async () => {
        const res = await workerApi.changeGroup(group.value)
        if(res) { group.clear() }
    }

    const outHandler = async () => {
        const res = await workerApi.changeOut(out.value)
        if(res) { out.clear() }
    }

    const proofHandler = async () => {
        const res = await workerApi.changeProof(proof.value)
        if(res) { proof.clear() }
    }

    const jarHandler = async () => {
        const res = await workerApi.changeJar(jar.value)
        if(res) { jar.clear() }
    }

    return (
        <div className={styles.main}>
            <div className={styles.row}>
                <div className={styles.label}>Password</div>
                <Input input={password} placeholder='Password' type='password' />
                <div className={styles.button} onClick={() => passwordHandler()}>Push</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>2FA tg id</div>
                <Input input={telegram} placeholder='telegram Id' />
                <div className={styles.button} onClick={() => telegramHandler()}>Push</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Push tg id</div>
                <Input input={group} placeholder='telegram group Id' />
                <div className={styles.button} onClick={() => groupHandler()}>Push</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Out tg id</div>
                <Input input={out} placeholder='telegram out Id' />
                <div className={styles.button} onClick={() => outHandler()}>Push</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Proof tg id</div>
                <Input input={proof} placeholder='telegram proof-group Id' />
                <div className={styles.button} onClick={() => proofHandler()}>Push</div>
            </div>
            <div className={styles.row}>
                <div className={styles.label}>Jar tg id</div>
                <Input input={jar} placeholder='telegram jar Id' />
                <div className={styles.button} onClick={() => jarHandler()}>Push</div>
            </div>
        </div>
    )
}

export default Settings 