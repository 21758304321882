import { LOAD, CLEAR, REFRESH } from './types/workers.types'

const initialState = {
    list: [],
    triger: false
}

export default function workersReducer(state=initialState, action) {
    switch(action.type) {
    case LOAD:
        return {...action.payload, triger: !state.triger }
    case REFRESH:
        return {list: state.list.map((worker) => {
            if(worker._id === action.payload._id) { return action.payload }
            return worker
        })}
    case CLEAR:
        return {...initialState}
    default:
        return state
    }
}