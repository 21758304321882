import useApi from '../hooks/api.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'


export default function useUnknowApi() {
    const { protectedRequest } = useApi()

    const filter = useSelector(filterSelectors.unknow)

    const list = async (page=1, limit=20) => {
        try { return await protectedRequest('api/unknow/worker-list', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const listAll = async (page=1, limit=20) => {
        try { return await protectedRequest('api/unknow/list', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const create = async (card, amount, time, description) => {
        try { return await protectedRequest('api/unknow/create', {card, amount, time, description}) }
        catch(error) { return false } 
    }

    const assign = async (id, partner) => {
        try { return await protectedRequest('api/unknow/assign', {id, partner}) }
        catch(error) { return null } 
    }

    return { 
        list,
        listAll,
        create,
        assign
    }
}