import { useDispatch } from 'react-redux'

import * as filterActions from '../redux/actions/filter.actions'

export default function useFilter() {
    const dispatch = useDispatch()

    const setUnknowsFilter = (filter) => { dispatch(filterActions.setUnknows(filter)) } 
    const setPaymentsFilter = (filter) => { dispatch(filterActions.setPayments(filter)) } 
    const setInvoicesFilter = (filter) => { dispatch(filterActions.setInvouces(filter)) } 
    const setCardsFilter = (filter) => { dispatch(filterActions.setCards(filter)) } 
    const setPartnersFilter = (filter) => { dispatch(filterActions.setPartners(filter)) } 
    const setWorkersFilter = (filter) => { dispatch(filterActions.setWorkers(filter)) } 
    const setWithdrawsFilter = (filter) => { dispatch(filterActions.setWithdraws(filter)) } 

    const clearUnknowsFilter = () => { dispatch(filterActions.clearUnknows()) }
    const clearPaymentsFilter = () => { dispatch(filterActions.clearPayments()) }    
    const clearInvoicesFilter = () => { dispatch(filterActions.clearInvoices()) }
    const clearCardsFilter = () => { dispatch(filterActions.clearCards()) }
    const clearPartnersFilter = () => { dispatch(filterActions.clearPartners()) }
    const clearWorkersFilter = () => { dispatch(filterActions.clearWorkers()) }
    const clearWithdrawsFilter = () => { dispatch(filterActions.clearWithdraws()) }

    const clear = () => {
        dispatch(filterActions.clearUnknows())
        dispatch(filterActions.clearPayments())
        dispatch(filterActions.clearInvoices())
        dispatch(filterActions.clearCards())
        dispatch(filterActions.clearPartners())
        dispatch(filterActions.clearWorkers())
        dispatch(filterActions.clearWithdraws())
    }

    return {
        setUnknowsFilter,
        setPaymentsFilter,
        setInvoicesFilter,
        setCardsFilter,
        setPartnersFilter,
        setWorkersFilter,
        setWithdrawsFilter,

        clearUnknowsFilter,
        clearPaymentsFilter,
        clearInvoicesFilter,
        clearCardsFilter,
        clearPartnersFilter,
        clearWorkersFilter,
        clearWithdrawsFilter,

        clear
    }
}