import useApi from '../hooks/api.hook'


export default function useWorkerApi() {
    const { publicRequest, protectedRequest } = useApi()

    const twoFA = async (login, password) => {
        try { return await publicRequest('api/worker/2fa', {login, password}) }
        catch(error) { return null } 
    }

    const verify = async (login, password, code) => {
        try { return await publicRequest('api/worker/login', {login, password, code}) }
        catch(error) { return null } 
    }

    const changePassword = async (password) => {
        try { return await protectedRequest('api/worker/change-password', {password}) }
        catch(error) { return [] } 
    }

    const changeTelegram = async (telegram) => {
        try { return await protectedRequest('api/worker/change-telegram', {telegram}) }
        catch(error) { return [] } 
    }
    
    const changeGroup = async (group) => {
        try { return await protectedRequest('api/worker/change-group', {group}) }
        catch(error) { return [] } 
    }

    const changeOut = async (out) => {
        try { return await protectedRequest('api/worker/change-out', {out}) }
        catch(error) { return [] } 
    }

    const changeProof = async (proof) => {
        try { return await protectedRequest('api/worker/change-proof', {proof}) }
        catch(error) { return [] } 
    }

    const changeJar = async (jar) => {
        try { return await protectedRequest('api/worker/change-jar', {jar}) }
        catch(error) { return [] } 
    }

    const setInpPriority = async (id, priority) => {
        try { return await protectedRequest('api/worker/set-cards-priority', {id, priority}) }
        catch(error) { return [] } 
    }

    const setOutPriority = async (id, priority) => {
        try { return await protectedRequest('api/worker/set-priority', {id, priority}) }
        catch(error) { return [] } 
    }

    const getStatistics = async (type="Day", start=null, stop=null) => {
        try { return await protectedRequest('api/worker/statistic', {type, start, stop}) }
        catch(error) { return null } 
    }

    const getBalance = async () => {
        try { return await protectedRequest('api/worker/balance') }
        catch(error) { return 0 } 
    }

    const getWaitBalance = async () => {
        try { return await protectedRequest('api/worker/balance-wait') }
        catch(error) { return 0 } 
    }

    const cardsConversion = async () => {
        try { return await protectedRequest('api/worker/cards-conversion') }
        catch(error) { return null } 
    }

    const getOutActive = async () => {
        try { return await protectedRequest('api/worker/is-out-active') }
        catch(error) { return true } 
    }

    const toggleOutActive = async () => {
        try { return await protectedRequest('api/worker/toggle-out-active') }
        catch(error) { return true } 
    }

    const toggleIban = async () => {
        try { return await protectedRequest('api/worker/toggle-iban') }
        catch(error) { return true } 
    }
    
    const getIbanActive = async () => {
        try { return await protectedRequest('api/worker/is-iban-active') }
        catch(error) { return true } 
    }

    const get = async (id) => {
        try { return await protectedRequest('api/worker/get', {id}) }
        catch(error) { return null } 
    }

    const list = async () => {
        try { return await protectedRequest('api/worker/list') }
        catch(error) { return [] } 
    }

    return { 
        twoFA,
        verify,
        changePassword,
        changeTelegram,
        changeGroup,
        changeOut,
        changeProof,
        changeJar,
        getBalance,
        getWaitBalance,
        getOutActive,
        cardsConversion,

        getStatistics,

        setInpPriority,
        setOutPriority,

        get,
        list,

        toggleOutActive,
        toggleIban,
        getIbanActive
    }
}