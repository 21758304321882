import useApi from '../hooks/api.hook'


export default function useCardApi() {
    const { protectedRequest } = useApi()

    const create = async (number, partner, description) => {
        try { return await protectedRequest('api/card/create', {number, partner, description}) }
        catch(error) { return null } 
    }

    const setPartner = async (id, partner) => {
        try { return await protectedRequest('api/card/set-partner', {id, partner}) }
        catch(error) { return null } 
    }

    const setLimits = async (id, min, max, count, total) => {
        try { return await protectedRequest('api/card/set-limits', {id, min, max, count, total}) }
        catch(error) { return null } 
    }

    const get = async (id) => {
        try { return await protectedRequest('api/card/get', {id}) }
        catch(error) { return null } 
    }

    const turn = async (id) => {
        try { return await protectedRequest('api/card/turn', {id}) }
        catch(error) { return null } 
    }

    const turnWatch = async (id) => {
        try { return await protectedRequest('api/card/turn-watch', {id}) }
        catch(error) { return null } 
    }

    const reset = async (id) => {
        try { return await protectedRequest('api/card/reset', {id}) }
        catch(error) { return null } 
    }

    const resetTotal = async (id) => {
        try { return await protectedRequest('api/card/reset-total', {id}) }
        catch(error) { return null } 
    }

    const resetAll = async () => {
        try { return await protectedRequest('api/card/reset-all') }
        catch(error) { return null } 
    }

    const resetTotalAll = async () => {
        try { return await protectedRequest('api/card/reset-all-total') }
        catch(error) { return null } 
    }

    const remove = async (id) => {
        try { return await protectedRequest('api/card/remove', {id}) }
        catch(error) { return false } 
    }

    const setPriority = async (id, priority) => {
        try { return await protectedRequest('api/card/set-priority', {id, priority}) }
        catch(error) { return false } 
    }

    const setOperator = async (id, operator) => {
        try { return await protectedRequest('api/card/set-operator', {id, operator}) }
        catch(error) { return null } 
    }

    const setJar = async (id, hash) => {
        try { return await protectedRequest('api/card/set-jar', {id, hash}) }
        catch(error) { return null } 
    }

    const setBank = async (id, bank) => {
        try { return await protectedRequest('api/card/set-bank', {id, bank}) }
        catch(error) { return null } 
    }

    const list = async () => {
        try { return await protectedRequest('api/card/list') }
        catch(error) { return [] } 
    }

    const listAll = async () => {
        try { return await protectedRequest('api/card/list-all') }
        catch(error) { return [] } 
    }

    return { 
        create,
        get, 
        setPartner,
        setLimits,
        turn,
        turnWatch,
        reset,
        resetTotal,
        resetAll,
        resetTotalAll,
        remove,
        setPriority,
        setOperator,
        setJar,
        setBank,
        list,
        listAll
    }
}