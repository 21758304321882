import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Input from '../components/UI/Input'
import styles from '../styles/pages/card.module.css'
import useInput from '../hooks/input.hook'
import useAlert from '../hooks/alert.hook'

import useMonoApi from '../API/mono.api'


function Mono() {
  const { pushMess } = useAlert()
  const monoApi = useMonoApi()
  
  const token = useInput() 
  const description = useInput() 


  const navigate = useNavigate()
  
  const createHeandler = async () => {
    const card = await monoApi.create(token.value, description.value)
    if(!card) { return }

    pushMess('Successeful')
    navigate('/monos')
  } 

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.label}>Token</div>
        <Input input={token} placeholder='Token' />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Description</div>
        <Input input={description} placeholder='Description' />
      </div>
      <div className={styles.buttons}>
        <button className={`${styles.button} ${styles.button_main}`} onClick={() => createHeandler()}>Push</button>
        <Link className={styles.button} to={'/monos'}>Cancel</Link>
      </div>
    </div>
  )
}

export default Mono