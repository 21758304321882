import React, { useEffect } from 'react'
import styles from '../../styles/components/amount.module.css'
import Input from './Input'
import useInput from '../../hooks/input.hook'


function ClientId({handler, value=''}) {
    const client = useInput(value)

    useEffect(() => { handler(client.value) }, [client.value])
    
    return (
        <div className={styles.main}>
            <Input input={client} placeholder='Client Id' />
        </div>
    )
}

export default ClientId