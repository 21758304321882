import React from 'react'
import styles from '../styles/components/unknow.module.css'
import { formatTime } from '../utils/time.utils'


function Unknow({unknow}) {
    return (
        <div className={styles.card}>
            <div>{unknow?.amount} ₴</div>
            <div>{unknow?.cardNumber}</div>
            <div>{unknow?._id}</div>
            <div className={styles.date}>{formatTime(unknow?.time)}</div>
        </div>
      )
}

export default Unknow