import { useState } from 'react'
import useInput from './input.hook'
import { getTimestamp } from '../utils/time.utils'


export default function useRange(initStart='', initStop='') {
    const [startTimestamp, setStartTimestamp] = useState(getTimestamp(initStart))
    const [stopTimestamp, setStopTimestamp] = useState(getTimestamp(initStop))

    const setStart = (value) => { setStartTimestamp(getTimestamp(value)) }
    const setStop = (value) => { setStopTimestamp(getTimestamp(value)) }

    const start = useInput(initStart, setStart)
    const stop = useInput(initStop, setStop)

    const setValue = (startValue='', stopValue='') => {
        start.changeValue(startValue)
        stop.changeValue(stopValue)
    }

    return {
        bind: { start, stop },
        value: { start: startTimestamp, stop: stopTimestamp },
        setValue
    }
}