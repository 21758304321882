import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useWorkerApi from '../../API/worker.api'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'
import useAdminApi from '../../API/admin.api'
import useFilter from '../../hooks/filter.hook'
import { useNavigate } from 'react-router-dom'
import styles from '../../styles/pages/statistic.module.css'
import useChart from '../../hooks/chart.hook'
import { getDate } from '../../utils/time.utils'


function Worker() {
    const { id } = useParams()
    const workerApi = useWorkerApi()
    const adminApi = useAdminApi()

    const navigate = useNavigate() 

    const range = useRange(getDate(new Date(Date.now())))

    const [type, setType] = useState('Day') 
    const [triger, setTriger] = useState(false)

    const [worker, setWorker] = useState(null) 
    const [statistic, setStatistic] = useState(null)

    const canvasTotal = useRef()
    const canvasConvercion = useRef()
    const canvasProfit = useRef()

    const totalChart = useChart(canvasTotal, 'bar')
    const ConvercionChart = useChart(canvasConvercion, 'line', {scales: { y: { min: 0, max: 1 }}})
    const ProfitChart = useChart(canvasProfit, 'bar')

    const setTypeHandler = (type) => {
        setType(type)

        let startDate = ''

        if(type === 'Day') { startDate = getDate(new Date(Date.now())) }
        if(type === 'Weak') { startDate = getDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 6)) }
        if(type === 'Month') { startDate = getDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)) }
        
        range.setValue(startDate)

        setTriger((prew) => !prew)
    }

    const loadStatistic = async (costumLoad=true) => { 
        if(costumLoad) { setType('Custom') }

        const { start, stop } = range.value 
        const res = await adminApi.workerStatistic(id, start, stop)
        setStatistic(res)        

        const getLabel = (item) => {
            if(type === 'Day') { return `${item._id.slice(-2)}:00` }
            return `${item._id.slice(-2)}`
        }

        totalChart.setData(res.invoices.data.map(getLabel), [ { label: "Total amount", data: res.invoices.data.map((item) => item.totalConfirm) }])
        ConvercionChart.setData(res.invoices.data.map(getLabel), [ { label: "Conversion", data: res.invoices.data.map((item) => item.conversion) }])
        ProfitChart.setData(res.invoices.data.map(getLabel), [ { label: "Profit", data: res.invoices.data.map((item) => item.profit) }])
    } 

    useEffect(() => { loadStatistic(false).then() }, [triger])

    useEffect(() => { 
        const load = async () => { setWorker(await workerApi.get(id)) }
        load()
    }, [])

    const filter = useFilter()

    const checkInvoices = () => {
        filter.clearInvoicesFilter()
        filter.setInvoicesFilter({ worker: id })

        navigate('/invoices')
    }

    const checkPayments = () => {
        filter.clearPaymentsFilter()
        filter.setPaymentsFilter({ worker: id })

        navigate('/payments')
    }

    const checkUnknows = () => {
        filter.clearUnknowsFilter()
        filter.setUnknowsFilter({ worker: id })

        navigate('/unknows')
    }

    const [invoiceIsUpdate, setInvoiceIsUpdate] = useState(false)
    const [invoiceIsClient, setInvoiceIsClient] = useState(false)
    const [paymentIsUpdate, setPaymentIsUpdate] = useState(false)

    
    const downloadInvoiceReport = async () => { 
        const { start, stop } = range.value

        const url = await adminApi.invoiceReport(start, stop, invoiceIsUpdate, invoiceIsClient, { worker: id })
    
        window.open(url) 
    } 

    const downloadPaymentReport = async () => { 
        const { start, stop } = range.value

        const url = await adminApi.paymentReport(start, stop, paymentIsUpdate, { worker: id })
    
        window.open(url) 
    } 

    return (
        <div className='w-100'>
            <h2>{worker?.login}</h2>
            <div>Id: {worker?._id}</div>
            <div>Balance: {worker?.curent}</div>
            <br />

            <div className='d-flex'>
                <Range range={range.bind} />
                <button className='button' onClick={() => loadStatistic()}>Load</button>
            </div>

            <div className={styles.switch}>
                <div className={`${styles.button} ${type === 'Month'? styles.active : null}`} onClick={() => setTypeHandler('Month')}>Month</div>
                <div className={`${styles.button} ${type === 'Weak'? styles.active : null}`} onClick={() => setTypeHandler('Weak')}>Weak</div>
                <div className={`${styles.button} ${type === 'Day'? styles.active : null}`} onClick={() => setTypeHandler('Day')}>Day</div>
            </div>

            <br />

            <div className={styles.main}>
                <div className={styles.info}>
                    <div>
                        <h3>Invoices</h3>
                        <div><b>count:</b> {statistic?.invoices?.count}</div>
                        <div><b>conversion:</b> {parseInt(statistic?.invoices?.conversion * 100)}%</div>
                        <div><b>Avarage Time:</b> {parseInt(statistic?.invoices?.avarageTime / (1000 * 60))} min {parseInt(statistic?.invoices?.avarageTime / 1000) % 60} s</div>
                        <div><b>Avarage Summ:</b> {parseInt(statistic?.invoices?.avarageSum)} uah</div>
                        <div><b>Total Summ:</b> {parseInt(statistic?.invoices?.total.toFixed(2))} uah</div>
                        <div><b>Commision:</b> {statistic?.invoices?.profit.toFixed(2)} uah</div>
                        <div><b>change:</b> {statistic?.invoices?.changeCount}</div>
                    </div>
                    <div>
                        <div className='d-flex f-wrap'>
                            <button className='button' onClick={checkInvoices}>Check Invoices</button>
                            <div>
                                <input type="checkbox" checked={invoiceIsUpdate} onChange={() => setInvoiceIsUpdate((prev) => !prev)}/>
                                <span>Udate At</span>
                            </div>
                            <div>
                                <input type="checkbox" checked={invoiceIsClient} onChange={() => setInvoiceIsClient((prev) => !prev)}/>
                                <span>Client Id</span>
                            </div>
                            <button className='button' onClick={downloadInvoiceReport}>Get Report</button>
                        </div>
                    </div>
                    <div>
                        <h3>Payments</h3>
                        <div>count: {statistic?.payments?.count}</div>
                        <div>conversion: {parseInt((statistic?.payments?.confirm / statistic?.payments?.count) * 100)}%</div>
                        <div>Avarage Time: {parseInt(statistic?.payments?.time / (1000 * 60))} min {parseInt(statistic?.payments?.time / 1000) % 60} s</div>
                        <div>Avarage Summ: {parseInt(statistic?.payments?.summ)} uah</div>
                        <div>Total Summ: {parseInt(statistic?.payments?.profitSum)} uah</div>
                    </div>
                    <div className='d-flex f-wrap'>
                        <button className='button' onClick={checkPayments}>Check Payments</button>
                        <div>
                            <input type="checkbox" checked={paymentIsUpdate} onChange={() => setPaymentIsUpdate((prev) => !prev)}/>
                            <span>Udate At</span>
                        </div>

                        <button className='button' onClick={downloadPaymentReport}>Get Report</button>
                    </div>
                    <div>
                        <h3>Unknows</h3>
                        <div>count: {statistic?.unknows?.count}</div>
                        <div>Avarage Summ: {parseInt(statistic?.unknows?.summ)} uah</div>
                    </div>
                    <div>
                        <button className='button' onClick={checkUnknows}>Check Unknows</button>
                    </div>
                </div>
                <div className={styles.view}>
                    <canvas id="total" ref={canvasTotal}></canvas>
                    <canvas id="conversion" ref={canvasConvercion}></canvas>
                    <canvas id="profit" ref={canvasProfit}></canvas>
                </div>
            </div>
        </div>
    )
}

export default Worker