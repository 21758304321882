import React from 'react'
import { useSelector } from 'react-redux'

import styles from '../../styles/components/admin/worker.module.css'
import { Link } from 'react-router-dom'
import useInput from '../../hooks/input.hook'
import Input from '../UI/Input'
import useWorkerApi from '../../API/worker.api'
import useWorkers from '../../hooks/workers.hook'
import useAdminApi from '../../API/admin.api'
import * as authSelectors from '../../redux/selectors/auth.selectors'


function Worker({worker}) {  
  const access = useSelector(authSelectors.adminAccess)
  const editAccess = (access === 'ADMIN')

  const Worker = useWorkerApi()
  const Admin = useAdminApi()

  const { load } = useWorkers()

  const priority = useInput(worker.priority)
  const cardPriority = useInput(worker.cardPriority)

  const inpPriorityHandler = async () => {
    await Worker.setInpPriority(worker._id, cardPriority.value)
    await load()
  }

  const outPriorityHandler = async () => {
    await Worker.setOutPriority(worker._id, priority.value)
    await load()
  }

  const outToggleHandler = async () => {
    await Admin.toggleWorkerOut(worker._id)
    await load()
  }

  const inToggleHandler = async () => {
    await Admin.toggleWorkerIn(worker._id)
    await load()
  }

  const IbanToggleHandler = async () => {
    await Admin.toggleWorkerIban(worker._id)
    await load()
  }

  return (
    <div className={styles.card}>
        <div><Link to={`/worker/${worker?._id}`} >{worker?.login}</Link></div>
        <div>{worker?._id}</div>
        {editAccess? <>
          <div>
            <Input input={cardPriority} className={styles.input}/>
            <button className={styles.priorityBtn} onClick={() => inpPriorityHandler()}>SET</button>
            <button className={`${styles.priorityBtn} ${worker?.invoiceActive? styles.off : styles.on}`} onClick={() => inToggleHandler()}>
              {worker?.invoiceActive? 'off' : 'on'}
            </button>
          </div>
          <div>
            <Input input={priority} className={styles.input}/>
            <button className={styles.priorityBtn} onClick={() => outPriorityHandler()}>SET</button>
            <button className={`${styles.priorityBtn} ${worker?.paymentActive? styles.off : styles.on}`} onClick={() => outToggleHandler()}>
              {worker?.paymentActive? 'off' : 'on'}
            </button>
            <button className={`${styles.priorityBtn} ${worker?.ibanActive? styles.off : styles.on}`} onClick={() => IbanToggleHandler()}>
              {worker?.ibanActive? 'off Iban' : 'on Iban'}
            </button>
          </div>
        </> : <>
          <div>{worker.cardPriority}</div>
          <div>{worker.priority}</div>
        </>}

        <div className={styles.date}>
          {worker?.curent}
          <br />
          ({worker?.waitTotal})
        </div>
    </div>
  )
}

export default Worker