import React, { useState } from 'react'
import styles from '../../styles/components/select.module.css'


function MultiSelect({option, value, placeholder, handler=()=>{}}) {
    const optionValues = option.map((item) => item.value).filter((item) => (item !== 'ALL'))
    const [values, setValues] = useState(value.filter((item) => (optionValues.indexOf(item) !== -1)))

    const toggleValue = (value) => {
        if(value === 'ALL') {
            setValues([])
            return handler([])
        }

        setValues((prev) => {
            let newValues = prev 
            
            if(prev.indexOf(value) === -1) { newValues = [...prev, value] }
            else { newValues = prev.filter((item) => (item !== value)) }

            handler(newValues)

            return newValues
        })
    }

    const [open, setOpen] = useState(false)

    const optionClass = (value) => `${styles.option} ${values.indexOf(value) !== -1? styles.selected : null}`
    
    return (
        <div className={styles.main}>
            <div className={styles.value} onClick={() => setOpen((prev) => !prev)}>{placeholder}</div>
            <div className={`${styles.list} ${open? styles.open : null}`}>{
                option.map((item, key) => (
                    <div className={optionClass(item.value)} onClick={() => toggleValue(item.value)} key={key}>
                        {item.label}
                    </div>
                ))
            }</div>
        </div>
    )
}

export default MultiSelect