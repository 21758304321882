import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Auth from '../pages/Auth.page'
import AdminAuth from '../pages/AuthAdmin.page'



const notAuthRoutes = (
    <Routes>
        <Route path="/" element={<Auth />} exact />
        <Route path="/admin" element={<AdminAuth />} exact />
        <Route path="*" element={<Auth />} exact  />
    </Routes>
)

export default notAuthRoutes
