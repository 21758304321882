import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Pool from '../pages/Admin/Pool.page'
import Invoices from '../pages/Admin/Invoices.page'
import Payments from '../pages/Admin/Payments.page'


const supportRoutes = (
    <Routes>
        <Route path="/" element={<Invoices />} exact />
        <Route path="/invoices" element={<Invoices />} exact />
        <Route path="/payments" element={<Payments />} exact />
        <Route path="/pool" element={<Pool />} exact />

        <Route path="*" element={<Invoices />} exact />
    </Routes>
)     

export default supportRoutes