import React from "react"
import {useRoutes} from './routes/routes'
import * as authSelectors from './redux/selectors/auth.selectors'
import { useSelector } from "react-redux"
import Menu from "./sections/Menu.sections"
import Alert from './components/Alert'
import AdminMenu from "./sections/AdminMenu.sections"
import SupportMenu from "./sections/SupportMenu.sections"
import FinMenu from "./sections/FinMenu.sections"


function App() {
  const isAuth = useSelector(authSelectors.isAuth)
  const isAdmin = useSelector(authSelectors.isAdmin)
  const access = useSelector(authSelectors.adminAccess)

  const routes = useRoutes(isAuth, isAdmin, access)

  let menuList = {
    'ADMIN': <AdminMenu />,
    'SUPPORT':  <SupportMenu />,
    'FIN':  <FinMenu />,
    'NONE': null
  }

  return (
    <div className="app">
      <Alert />
      <div className="app-wrap">

        {isAuth && (isAdmin? menuList[access] : <Menu />)}

        <div className="app-main">
          {routes}
        </div>
      </div>
    </div>
  )
}

export default App
