import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { formatTime } from '../../utils/time.utils'
import * as partnersSelectors from '../../redux/selectors/partners.selectors'

import styles from '../../styles/components/admin/unknow.module.css'
import Select from '../UI/Select'
import useUnknowApi from '../../API/unknow.api'


function Unknow({unknow, refresh=()=>{}}) {
    const unknowApi = useUnknowApi()

    const partnersOptions = useSelector(partnersSelectors.dryOptions) || []

    const selectHandler = async (option) => {
        await unknowApi.assign(unknow._id, option.value)
        refresh()
    }

    const partnerSelect = <Select option={partnersOptions} handler={selectHandler} placeholder={'Select partner'} />

    return (
        <div className={styles.main}>
            <div>{unknow?.amount} ₴</div>
            <div>{unknow?.cardNumber}</div>
            <div>{unknow?.workerName || unknow?.worker}</div>
            <div className={unknow?.partner? null : styles.select}>
                {unknow?.partnerName || unknow?.partner || partnerSelect}
            </div>
            <div>{unknow?._id}</div>
            <div className={styles.date}>
                {formatTime(unknow?.time).replaceAll(',', " ")}
            </div>
        </div>
      )
}

export default Unknow