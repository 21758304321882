import React from 'react'
import { useSelector } from 'react-redux'
import useInput from '../../hooks/input.hook'
import useFilter from '../../hooks/filter.hook'

import Input from '../../components/UI/Input'
import Filter from '../../components/Filter'
import WorkerSelect from '../../components/WorkerSelect'

import * as filterSelectors from '../../redux/selectors/filter.selectors'
import Select from '../UI/Select'

const statusOption = [
    {value: true, label: 'active'}, 
    {value: false, label: 'inactive'},
    {value: null, label: 'all'}
]

function CardFilter() {
    const { setCardsFilter } = useFilter()

    const filter = useSelector(filterSelectors.card)
    const id = useInput(filter.id, (value) => setCardsFilter({id: value}))

    const filterOption = [
        { title: 'Status', elem: <Select option={statusOption} value={filter.status} handler={(option) => setCardsFilter({status: option.value})} placeholder="Status" /> },
        { title: 'Worker', elem: <WorkerSelect value={filter.worker} handler={(option) => setCardsFilter({worker: option.value})} /> },
    ]

    return (
        <>
            <Filter option={filterOption} />
            <Input input={id} placeholder='id' />
        </>
    )
}

export default CardFilter