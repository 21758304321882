import notAuthRoutes from './notAuth.routes'
import authRoutes from './auth.routes'
import adminRoutes from './admin.routes'
import finRoutes from './fin.routes'
import supportRoutes from './support.routes'


export const useRoutes = (isAuth, isAdmin, access) => {
    if(!isAuth) { return notAuthRoutes }

    if(isAdmin) { 
        if(access==='ADMIN') { return adminRoutes }
        if(access==='FIN') { return finRoutes }
        if(access==='SUPPORT') { return supportRoutes }
    }

    return authRoutes
}