import React from 'react'
import styles from '../../styles/components/input.module.css'


function Input({input, placeholder='', type='text', className}) {
  return (
    <input className={`${styles.input} ${className}`} type={type} {...input.bind} placeholder={placeholder} />
  )
}

export default Input