import { LOAD, CLEAR, REFRESH } from '../types/workers.types'


export function load(workers, waitBalance={}) {
    return {
        type: LOAD,
        payload: { 
            list: workers.map((worker) => {
                const item = {...worker}           
                item.waitTotal = waitBalance[worker._id]? waitBalance[worker._id] : 0
                return item
            })
        } 
    }
}

export function refresh(worker) {
    return {
        type: REFRESH,
        payload: worker
    }
}

export function clear() {
    return {
        type: CLEAR
    }
}
