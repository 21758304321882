import React, { useState } from 'react'
import styles from '../styles/components/payment.module.css'
import { formatTime } from '../utils/time.utils'
import usePaymentApi from '../API/payment.api'
import * as icons from './source/Icons.source'
import useWorker from '../hooks/worker.hook'


function Payment({payment, refresh = () => {}}) {
  const paymentApi = usePaymentApi()
  const Worker = useWorker()

  const [confirm, setConfirm] = useState(false)
  const [reject, setReject] = useState(false)

  const confirmHandler = async () => { 
    if(!confirm) { return setConfirm(true) }
    if(await paymentApi.confirm(payment?._id)) { Worker.update(); refresh() }
  }

  const rejectHandler = async () => { 
    if(!reject) { return setReject(true) }
    if(await paymentApi.reject(payment?._id)) { refresh() }
  }
  
  return (
    <div className={styles.card}>
        <div>{payment?.amount} ₴</div>
        <div className={styles.iban}>
            <div>{payment?.card}</div>
            <div>{payment?.iban?.number}</div>
            <div>{payment?.iban?.recipient}</div>
            <div>{payment?.iban?.INN}</div>
            <div>{payment?.iban?.number && (payment?.iban?.target || 'Переказ коштів')}</div>
        </div>
        <div>{payment?._id}</div>
        <div>
            <div className={`${styles.status} ${payment?.status}`}>
              {payment?.status}
            </div>
        </div>
        <div className={styles.actions}>
            {payment?.status === 'WAIT' && <>
              <div className={`${styles.button} ${styles.on} ${confirm? styles.active : null} ${reject? 'hide' : null}`} 
                   onClick={() => confirmHandler()}>
                {icons.check}
              </div>
              <div className={`${styles.button} ${styles.off} ${reject? styles.active : null} ${confirm? 'hide' : null}`} 
                   onClick={() => rejectHandler()}>
                {icons.dismis}
              </div>
            </>}
        </div>
        <div className={styles.date}>
          {formatTime(payment?.createdAt).replaceAll(',', '')}
        </div>
    </div>
  )
}

export default Payment