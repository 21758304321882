import React, { useState } from 'react'
import styles from '../styles/components/invoice.module.css'
import { formatTime } from '../utils/time.utils'
import useInvoiceApi from '../API/invoice.api'
import useWorker from '../hooks/worker.hook'


function Invoice({invoice, refresh = () => {}}) {
  const Worker = useWorker()
  const invoiceApi = useInvoiceApi()
  
  const [ok, setOk] = useState(false)
  const [active, setAvtive] = useState(true)

  const confirmHandler = async () => { 
    if(!active) { return }
    if(!ok) { return setOk(true) }

    setAvtive(false)

    if(await invoiceApi.confirm(invoice?._id)) { Worker.update(); refresh() }
    else { setAvtive(true) }
  }
  
  return (
    <div className={styles.card}>
        <div>{invoice?.emergent && "IS TECH"} {invoice?.amount} ₴</div>
        <div>{invoice?.cardNumber}</div>
        <div>{invoice?._id}</div>
        <div>
            <div className={`${styles.status} ${invoice?.status}`}>
              {invoice?.status}
            </div>
        </div>
        <div className={styles.actions}>
            {invoice?.status === 'WAIT' && !invoice?.emergent &&
              <div className={`${styles.button} ${ok? styles.active : null} ${styles.on}`} 
                   onClick={() => confirmHandler()}>ok</div>
            }
        </div>
        <div className={styles.date}>
          {formatTime(invoice?.createdAt).replaceAll(',', '')}
        </div>
    </div>
  )
}

export default Invoice