import React, { useEffect, useRef, useState } from 'react'
import useWorkerApi from '../API/worker.api'
import styles from '../styles/pages/statistic.module.css'
import useChart from '../hooks/chart.hook'
import Range from '../components/Range'
import useRange from '../hooks/range.hook'


function Statistic() {
    const workerApi = useWorkerApi()

    const [type, setType] = useState('Day') 

    const canvasTotal = useRef()
    const canvasConvercion = useRef()
    const canvasProfit = useRef()

    const totalChart = useChart(canvasTotal, 'bar')
    const ConvercionChart = useChart(canvasConvercion, 'line', {scales: { y: { min: 0, max: 1 }}})
    const ProfitChart = useChart(canvasProfit, 'bar')

    const [invoice, setInvoice] = useState(null)
    const [payment, setPayment] = useState(null)

    const range = useRange()
    const [triger, setTriger] = useState(false)


    useEffect(() => {
        const load = async () => {
            const { start, stop } = range.value

            const data = await workerApi.getStatistics(type, start, stop)
            if(!data) { return }
            const {invoice, payment} = data

            setInvoice({...invoice, data: undefined})
            setPayment({...payment, data: undefined})

            const getLabel = (item) => {
                if(type === 'Day') { return `${item._id.slice(-2)}:00` }
                return `${item._id.slice(-2)}`
            }

            totalChart.setData(invoice.data.map(getLabel), [ { label: "Total amount", data: invoice.data.map((item) => item.totalConfirm) }])
            ConvercionChart.setData(invoice.data.map(getLabel), [ { label: "Conversion", data: invoice.data.map((item) => item.conversion) }])
            ProfitChart.setData(invoice.data.map(getLabel), [ { label: "Profit", data: invoice.data.map((item) => item.profit) }])
        }

        load()
    }, [triger])

    const setTypeHandler = (type) => {
        setType(type)
        setTriger((prew) => !prew)
    }

    return (
        <div className={styles.wrap}>
            <div className='d-flex'>
                <Range range={range.bind} />
                <button className={styles.load} onClick={() => setTypeHandler('Custom')}>Load</button>
            </div>

            <div className={styles.switch}>
                <div className={`${styles.button} ${type === 'Month'? styles.active : null}`} onClick={() => setTypeHandler('Month')}>Month</div>
                <div className={`${styles.button} ${type === 'Weak'? styles.active : null}`} onClick={() => setTypeHandler('Weak')}>Weak</div>
                <div className={`${styles.button} ${type === 'Day'? styles.active : null}`} onClick={() => setTypeHandler('Day')}>Day</div>
            </div>
            <div className={styles.main}>
                <div className={styles.info}>
                    <div>
                        <h3>Invoices</h3>
                        <p>Count: {invoice?.count}</p>
                        <p>Conversion: {parseInt(invoice?.conversion * 100)}%</p>
                        <p>Total: {parseInt(invoice?.total)} UAH</p>
                        <p>Profit: {parseInt(invoice?.profit)} UAH</p>
                        <div>Avarage Time: {parseInt(invoice?.avarageTime / (1000 * 60))} min {parseInt(invoice?.avarageTime / 1000) % 60} s</div>
                    </div>
                    <div>
                        <h3>Payment</h3>
                        <p>Count: {parseInt(payment?.count)} UAH</p>
                        <p>Conversion: {parseInt(payment?.conversion * 100)}%</p>
                        <p>Total: {parseInt(payment?.total)} UAH</p>
                        <div>Avarage Time: {parseInt(payment?.avarageTime / (1000 * 60))} min {parseInt(payment?.avarageTime / 1000) % 60} s</div>
                    </div>
                </div>
                <div className={styles.view}>
                    <canvas id="total" ref={canvasTotal}></canvas>
                    <canvas id="conversion" ref={canvasConvercion}></canvas>
                    <canvas id="profit" ref={canvasProfit}></canvas>
                </div>
            </div>
        </div>
    )
}

export default Statistic