import React from 'react'
import styles from '../styles/sections/menu.module.css'
import * as icons from '../components/source/Icons.source'
import { NavLink } from 'react-router-dom'
import useAuth from '../hooks/auth.hook'


function FinMenu() {
    const { logout } = useAuth()

    return (
        <div className={styles.main}>
            <div className={styles.menu}>
                <NavLink to={'/'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.home}
                    </div>
                    <div className={styles.value}>
                        Home
                    </div>
                </NavLink>
                <br />
                <NavLink to={'./invoices'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Pay-in
                    </div>
                </NavLink>
                <NavLink to={'./payments'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Pay-out
                    </div>
                </NavLink>
                <NavLink to={'./unknows'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Unknows
                    </div>
                </NavLink>
                <NavLink to={'./withdraws'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Withdraw
                    </div>
                </NavLink>
                <NavLink to={'./partners'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.profile}
                    </div>
                    <div className={styles.value}>
                        Partners
                    </div>
                </NavLink>
                <NavLink to={'./workers'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.profile}
                    </div>
                    <div className={styles.value}>
                        Workers
                    </div>
                </NavLink>
                <NavLink to={'./swap'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Swap
                    </div>
                </NavLink>
                <NavLink to={'./withdraw'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Withdraw
                    </div>
                </NavLink>
            </div>
            <div className={styles.logout}>
                <div className={styles.item} onClick={logout}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FinMenu