import { LOAD, CLEAR, REFRESH } from './types/partners.types'

const initialState = {
    list: []
}

export default function partnersReducer(state=initialState, action) {
    switch(action.type) {
    case LOAD:
        return {...action.payload}
    case REFRESH:
        return {list: state.list.map((partner) => {
            if(partner._id === action.payload._id) { return action.payload }
            return partner
        })}
    case CLEAR:
        return {...initialState}
    default:
        return state
    }
}