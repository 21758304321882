import { useDispatch } from 'react-redux'

import * as partners from '../redux/actions/partner.actions'
import usePartnerApi from '../API/partner.api'
import useAdminApi from '../API/admin.api'


export default function usePartners() {
    const partnerApi = usePartnerApi()
    const adminApi = useAdminApi()

    const dispath = useDispatch()

    // 
    const load = async () => { dispath(partners.load(await partnerApi.list(), await adminApi.getPartnersWaitBalance())) }
    const loadNames = async () => { dispath(partners.loadNames(await partnerApi.namesList())) }
    const refresh = async (id) => { dispath(partners.refresh(await partnerApi.get(id))) }
    const clear = () => { dispath(partners.clear()) }

    return { 
        load, 
        loadNames,
        refresh,
        clear
    }
}