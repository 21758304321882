import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Invoices from '../pages/Invoices.page'
import Payments from '../pages/Payments.page'
import Unknows from '../pages/Unknows.page'
import Pool from '../pages/Pool.page'
import CreateCard from '../pages/CreateCard.page'
import Unknow from '../pages/Unknow.page'
import Settings from '../pages/Settings.page'
import Card from '../pages/Card.page'
import Mono from '../pages/Mono.page'
import Monos from '../pages/Monos.page'
import Statistic from '../pages/Statistic.page'
import Withdraws from '../pages/Withdraws.page'


const authRoutes = (
    <Routes>
        <Route path="/" element={<Invoices />} exact />
        <Route path="/invoices" element={<Invoices />} exact />
        <Route path="/payments" element={<Payments />} exact />
        <Route path="/unknows" element={<Unknows />} exact />
        <Route path="/withdraw" element={<Withdraws />} exact />
        <Route path="/monos" element={<Monos />} exact />
        <Route path="/mono" element={<Mono />} exact />
        <Route path="/pool" element={<Pool />} exact />
        <Route path="/add-card" element={<CreateCard />} exact />
        <Route path="/card/:id" element={<Card />} exact />
        <Route path="/unknow" element={<Unknow />} exact />
        <Route path="/settings" element={<Settings />} exact />
        <Route path="/statistic" element={<Statistic />} exact />
        <Route path="*" element={<Invoices />} exact />
    </Routes>
)     

export default authRoutes