import { BASE_URL } from '../const'

const SingleConnections = (function () {
    let connections = {}

    function createConnection(url) { return new EventSource(url) }

    return {
        getConnection: function (url) {
            if(connections[url]) { connections[url].close() }
            connections[url] = createConnection(url)
            // if(!connections[url]) { connections[url] = createConnection(url) }
            return connections[url]
        }
    }
})()


export default function useConnect() {
    const getConnect = (endpoint, handler) => {
        return async () => {
            const url = `${BASE_URL}/${endpoint}`
            const eventSource = SingleConnections.getConnection(url)// const eventSource = new EventSource(url)
            eventSource.onmessage = handler
        }
    }

    return getConnect
}