import React, { useState } from 'react'
import styles from '../../styles/components/select.module.css'


function Select({option, value, placeholder, handler}) {
    const selctValue = option.filter((item) => (item.value === value))[0]

    const [select, setSelect] = useState(selctValue?.label || placeholder)
    const [open, setOpen] = useState(false)
    
    return (
        <div className={styles.main}>
            <div className={styles.value} onClick={() => setOpen((prev) => !prev)}>{select}</div>
            <div className={`${styles.list} ${open? styles.open : null}`}>{
                option.map((item, key) => (
                    <div className={styles.option} onClick={() => { 
                        setOpen(false)
                        setSelect(item.label)
                        handler(item)
                    }} key={key}>{item.label}</div>
                ))
            }</div>
        </div>
    )
}

export default Select