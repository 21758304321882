import useApi from '../hooks/api.hook'


export default function usePartnerApi() {
    const { protectedRequest } = useApi()

    const get = async (id) => {
        try { return await protectedRequest('api/partner/get', {id}) }
        catch(error) { return null } 
    }

    const list = async () => {
        try { return await protectedRequest('api/partner/list') }
        catch(error) { return [] } 
    }

    const namesList = async () => {
        try { return await protectedRequest('api/partner/worker-list') }
        catch(error) { return [] } 
    }

    return { 
        get, 
        list,
        namesList
    }
}