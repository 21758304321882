import React from 'react'
import { useSelector } from 'react-redux'
import useInput from '../../hooks/input.hook'
import useFilter from '../../hooks/filter.hook'

import Input from '../../components/UI/Input'

import * as filterSelectors from '../../redux/selectors/filter.selectors'


function PartnerFilter() {
    const { setPartnersFilter } = useFilter()

    const filter = useSelector(filterSelectors.partner)
    const id = useInput(filter?.id, (value) => setPartnersFilter({id: value}))

    return (
        <>
            <Input input={id} placeholder='id' />
        </>
    )
}

export default PartnerFilter