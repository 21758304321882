export const unknow = (state) => state.filter.unknows
export const unknowTriger = (state) => state.filter.unknowTriger

export const payment = (state) => state.filter.payments
export const paymentTriger = (state) => state.filter.paymentTriger

export const invoice = (state) => state.filter.invoices
export const invoiceTriger = (state) => state.filter.invoiceTriger

export const card = (state) => state.filter.cards
export const cardTriger = (state) => state.filter.cardTriger

export const partner = (state) => state.filter.partners
export const partnerTriger = (state) => state.filter.partnerTriger

export const worker = (state) => state.filter.workers
export const workerTriger = (state) => state.filter.workerTriger

export const withdraw = (state) => state.filter.withdraws
export const withdrawTriger = (state) => state.filter.withdrawTriger
