import useApi from '../hooks/api.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'


export default function useWithdrawApi() {
    const { protectedRequest } = useApi()

    const filter = useSelector(filterSelectors.withdraw)

    const listAll = async (page=1, limit=20) => {
        try { return await protectedRequest('api/withdraw/list-all', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const listWorker = async (page=1, limit=20) => {
        try { return await protectedRequest('api/withdraw/list-worker', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    return { 
        listAll,
        listWorker
    }
}