import { LOAD, CLEAR, REFRESH, ADD, REMOVE } from '../types/cards.types'


export function load(cards) {
    return {
        type: LOAD,
        payload: { list: cards } 
    }
}

export function add(card) {
    return {
        type: ADD,
        payload: card 
    }
}

export function remove(id) {
    return {
        type: REMOVE,
        payload: { id } 
    }
}

export function refresh(card) {
    return {
        type: REFRESH,
        payload: card
    }
}

export function clear() {
    return {
        type: CLEAR
    }
}
