import React, { useState } from 'react'
import WorkerSelect from '../../components/WorkerSelect'

import styles from '../../styles/pages/swap.module.css'
import Input from '../../components/UI/Input'
import useInput from '../../hooks/input.hook'
import useAdminApi from '../../API/admin.api'
import useAlert from '../../hooks/alert.hook'


function Swap() {
    const adminApi = useAdminApi()
    const Alert = useAlert()

    const [wait, setWait] = useState(false)

    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)

    const amount = useInput(0)
    const course = useInput(0)
    const tx = useInput('')

    const handler = async () => {
        if(wait) { return }
        setWait(true)
        
        const res = await adminApi.swap(from, to, amount.value, course.value, tx.value)
        if(res) { Alert.pushMess("Success") }

        setWait(false)
    }

    return (
        <div className={styles.main}>
            <div className={styles.form}>
                <h3>From:</h3>
                <WorkerSelect handler={(worker) => setFrom(worker.value)}/>
                <h3>To:</h3>
                <WorkerSelect handler={(worker) => setTo(worker.value)} />
                <h3>Amount: </h3>
                <Input input={amount} />
                <h3>Course: </h3>
                <Input input={course} placeholder='Course'/>
                <h3>Tx Id: </h3>
                <Input input={tx} placeholder='Tx Id'/>

                <button className={styles.button} onClick={() => handler()}>Create</button>
            </div>
        </div>
    )
}

export default Swap