import React, { useState } from 'react'
import Input from '../../components/UI/Input'
import WorkerSelect from '../../components/WorkerSelect'
import PartnerSelect from '../../components/PartnerSelect'
import useInput from '../../hooks/input.hook'
import useAlert from '../../hooks/alert.hook'
import useAdminApi from '../../API/admin.api'

import styles from '../../styles/pages/with.module.css'


function With() {
  const adminApi = useAdminApi()
  const Alert = useAlert()

  const [wait, setWait] = useState(false)

  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)

  const amount = useInput(0)
  const course = useInput(null)
  const tx = useInput(null)

  const handler = async () => {
      if(wait) { return }
      setWait(true)
      
      const res = await adminApi.withdraw(from, to, amount.value, course.value, tx.value)
      if(res) { Alert.pushMess("Success") }

      setWait(false)
  }

  return (
    <div className={styles.main}>
      <div className={styles.form}>
        <h3>From:</h3>
        <PartnerSelect handler={(partner) => setFrom(partner.value)}/>
        <h3>To:</h3>
        <WorkerSelect handler={(worker) => setTo(worker.value)} />
        <h3>Amount: </h3>
        <Input input={amount} />
        <h3>Course: </h3>
        <Input input={course} placeholder='Course'/>
        <h3>Tx Id: </h3>
        <Input input={tx} placeholder='Tx Id'/>

        <button className={styles.button} onClick={() => handler()}>Create</button>
      </div>
    </div>
  )
}

export default With