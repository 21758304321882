import React, { useEffect, useRef, useState } from 'react'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'
import useAdminApi from '../../API/admin.api'
import styles from '../../styles/pages/statistic.module.css'
import useChart from '../../hooks/chart.hook'
import { getDate } from '../../utils/time.utils'


function Statistics() {
    const adminApi = useAdminApi()

    const range = useRange(getDate(new Date(Date.now())))

    const [type, setType] = useState('Day') 
    const [triger, setTriger] = useState(false)
    const [statistic, setStatistic] = useState(null)

    const canvasTotal = useRef()
    const canvasConvercion = useRef()
    const canvasProfit = useRef()

    const totalChart = useChart(canvasTotal, 'bar')
    const ConvercionChart = useChart(canvasConvercion, 'line', {scales: { y: { min: 0, max: 1 }}})
    const ProfitChart = useChart(canvasProfit, 'bar')

    const setTypeHandler = (type) => {
        setType(type)

        let startDate = ''

        if(type === 'Day') { startDate = getDate(new Date(Date.now())) }
        if(type === 'Weak') { startDate = getDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 6)) }
        if(type === 'Month') { startDate = getDate(new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)) }
        
        range.setValue(startDate)

        setTriger((prew) => !prew)
    }

    const loadStatistic = async (costumLoad=true) => { 
        if(costumLoad) { setType('Custom') }

        const { start, stop } = range.value 
        const res = await adminApi.statistic(start, stop)
        setStatistic(res)    

        const getLabel = (item) => {
            if(type === 'Day') { return `${item._id.slice(-2)}:00` }
            return `${item._id.slice(-2)}`
        }

        totalChart.setData(res.invoices.data.map(getLabel), [ { label: "Total amount", data: res.invoices.data.map((item) => item.totalConfirm) }])
        ConvercionChart.setData(res.invoices.data.map(getLabel), [ { label: "Conversion", data: res.invoices.data.map((item) => item.conversion) }])
        ProfitChart.setData(res.invoices.data.map(getLabel), [ { label: "Profit", data: res.invoices.data.map((item) => item.profit) }])
    } 

    useEffect(() => { loadStatistic(false).then() }, [triger])

    return (
        <div className='w-100'>
            <h2>Statistics</h2>
            <br />
            <div className='d-flex'>
                <Range range={range.bind} />
                <button className='button' onClick={() => loadStatistic()}>Load</button>
            </div>

            <div className={styles.switch}>
                <div className={`${styles.button} ${type === 'Month'? styles.active : null}`} onClick={() => setTypeHandler('Month')}>Month</div>
                <div className={`${styles.button} ${type === 'Weak'? styles.active : null}`} onClick={() => setTypeHandler('Weak')}>Weak</div>
                <div className={`${styles.button} ${type === 'Day'? styles.active : null}`} onClick={() => setTypeHandler('Day')}>Day</div>
            </div>

            <div className={styles.main}>
                <div className={styles.info}>
                    <div>
                        <h3>Invoices</h3>
                        <div><b>count:</b> {statistic?.invoices?.count}</div>
                        <div><b>conversion:</b> {parseInt(statistic?.invoices?.conversion * 100)}%</div>
                        <div><b>Avarage Time:</b> {parseInt(statistic?.invoices?.avarageTime / (1000 * 60))} min {parseInt(statistic?.invoices?.avarageTime / 1000) % 60} s</div>
                        <div><b>Avarage Summ:</b> {parseInt(statistic?.invoices?.avarageSum)} uah</div>
                        <div><b>Total Summ:</b> {parseInt(statistic?.invoices?.total.toFixed(2))} uah</div>
                        <div><b>Commision:</b> {statistic?.invoices?.profit.toFixed(2)} uah</div>
                        <div><b>change:</b> {statistic?.invoices?.changeCount}</div>
                        <div><b>Not Find:</b> {statistic?.errors?.count}</div>
                    </div>
                    <div>
                        <h3>Payments</h3>
                        <div><b>count:</b> {statistic?.payments?.count}</div>
                        <div><b>conversion:</b> {parseInt((statistic?.payments?.confirm / statistic?.payments?.count) * 100)}%</div>
                        <div><b>Avarage Time:</b> {parseInt(statistic?.payments?.time / (1000 * 60))} min {parseInt(statistic?.payments?.time / 1000) % 60} s</div>
                        <div><b>Avarage Summ:</b> {parseInt(statistic?.payments?.summ)} uah</div>
                        <div><b>Total Summ:</b> {parseInt(statistic?.payments?.profitSum)} uah</div>
                    </div>
                    <div>
                        <h3>Unknows</h3>
                        <div><b>count:</b> {statistic?.unknows?.count}</div>
                        <div><b>Avarage Summ:</b> {parseInt(statistic?.unknows?.summ)} uah</div>
                    </div>
                </div>
                <div className={styles.view}>
                    <canvas id="total" ref={canvasTotal}></canvas>
                    <canvas id="conversion" ref={canvasConvercion}></canvas>
                    <canvas id="profit" ref={canvasProfit}></canvas>
                </div>
            </div>
        </div>
    )
}

export default Statistics