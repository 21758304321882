import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as auth from '../redux/actions/auth.actions'
import * as authSelectors from '../redux/selectors/auth.selectors'
import * as userSelectors from '../redux/selectors/user.selectors'
import useCards from './cards.hook'
import useWorker from './worker.hook'
import useWorkers from './workers.hook'
import usePartners from './partners.hook'
import useFilter from './filter.hook'


export default function useAuth() {
    const dispath = useDispatch()
    const token = useSelector(authSelectors.token)
    const isAdmin = useSelector(authSelectors.isAdmin)
    const isUserLoad = useSelector(userSelectors.isUserLoad)

    const Cards = useCards()
    const Partners = usePartners()
    const Workers = useWorkers()
    const Worker = useWorker()
    const Filter = useFilter()

    
    useEffect(() => { 
        if(token) { 
            if(isAdmin) {
                Cards.loadAll()
                Partners.load()
                Workers.load()
            }
            
            if(!isAdmin) { 
                Cards.load()
                Partners.loadNames()
                Worker.update()  
            }
        }
        if(!token) { 
            Cards.clear() 
            Partners.clear()
            Workers.clear()

            Filter.clear()
        }
    }, [token, isUserLoad, isAdmin])

    const login = (userToken, userId, isAdmin=false, access='NONE') => { dispath(auth.login(userToken, userId, isAdmin, access)) }
    const logout = () => { dispath(auth.logout()) }

    
    return { login, logout }
}