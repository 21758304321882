import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useAdminApi from '../../API/admin.api'
import usePaymentApi from '../../API/payment.api'
import usePage from '../../hooks/page.hook'
import PaymentFilter from '../../components/Admin/PaymentFilter'

import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/payments.module.css'
import Payment from '../../components/Admin/Payment'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'
import * as authSelectors from '../../redux/selectors/auth.selectors'


function Payments() {
  const access = useSelector(authSelectors.adminAccess)
  const reportAccess = (access === 'ADMIN' || access === 'FIN')

  const adminApi = useAdminApi()
  const paymentApi = usePaymentApi()

  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.paymentTriger)

  const [payments, setPayments] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)

  const range = useRange()
  
  const downloadReport = async () => {
    const { start, stop } = range.value
    const url = await adminApi.paymentReport(start, stop, isUpdate)
    window.open(url) 
  }  

  const load = async () => {
    const {list, count} = await paymentApi.listAll(page, pagination.limit)

    pagination.setCount(count)
    setPayments(list)
  }

  useEffect(() => { load() }, [page, triger])

  return (
    <div className='w-100'>
        {reportAccess && 
          <div className='d-flex'>
            <Range range={range.bind} />
            <div>
              <input type="checkbox" checked={isUpdate} onChange={() => setIsUpdate((prev) => !prev)}/>
              <span>Udate At</span>
            </div>
            <button className='report' onClick={downloadReport}>Report</button>
          </div>
        }
        
        <div className={styles.main}>
          <div className={styles.top}>
            <PaymentFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card/Iban</div>
              <div>Worker</div>
              <div>Partner</div>
              <div>ID</div>
              <div className={styles.status}>Status</div>
              <div className={styles.status}>Action</div>
              <div className={styles.date}>Created</div>
          </div>

          <div className={styles.table}>
              {payments.map((payment) => <Payment payment={payment} key={payment._id} refresh={() => load()}/>)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
        </div>
    </div>
  )
}

export default Payments