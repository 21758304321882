import React, { useEffect, useMemo, useState } from 'react'
import usePage from '../../hooks/page.hook'
import { useSelector } from 'react-redux'
import useCards from '../../hooks/cards.hook'
import Card from '../../components/Admin/Card'
import * as cardsSelectors from '../../redux/selectors/cards.selectors'
import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/cards.module.css'
import CardFilter from '../../components/Admin/CardFilter'
import useAdminApi from '../../API/admin.api'


function Pull() {  
  const cardsList = useSelector(cardsSelectors.filter())
  const count = useMemo(() => cardsList.length, [cardsList])

  const [cards, setCards] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const { loadAll } = useCards()
  const adminApi = useAdminApi()

  const pagination = usePage(30, count)
  const page = pagination.page

  const triger = useSelector(filterSelectors.cardTriger)
  
  useEffect(() => { (async () => loadAll())().then(() => setIsLoad(true)) }, [])

  useEffect(() => {
    const { begin, end } = pagination.getRange()

    setCards(cardsList.slice(begin, end))
  }, [page, triger, isLoad])


  const [conversion, setConversion] = useState(null)

  const conversionHandler = async () => {
      const conversion = await adminApi.cardsConversion()
      if(conversion) { setConversion(conversion) }
  }

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.top}>
          <CardFilter />
          <div onClick={() => conversionHandler()} className={styles.load}>LOAD</div>
        </div>

        

        <div className={styles.info}>
            <div>Card</div>
            <div>Desc</div>
            <div>Limits</div>
            <div>Partner</div>
            <div>Worker</div>
            <div>ID</div>              
            <div className={styles.status}>Status</div>
            <div className={styles.conv}>Conversion</div>
            <div className={styles.date}>time</div>
        </div>

        <div className={styles.table}>
            {cards.map((card) => <Card card={card} conversion={conversion} key={card._id} />)}
        </div>

        <div className={styles.bottom}>
            <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                Previos
            </button>
            <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                Next
            </button>
        </div>
      </div>
    </div>
  )
}

export default Pull