import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Input from '../components/UI/Input'
import styles from '../styles/pages/card.module.css'
import useInput from '../hooks/input.hook'
import useCardApi from '../API/card.api'
import useCards from '../hooks/cards.hook'
import useAlert from '../hooks/alert.hook'
import Select from '../components/UI/Select'

import * as partnersSelectors from '../redux/selectors/partners.selectors'


function CreateCard() {
  const { pushMess } = useAlert()
  const number = useInput() 
  const description = useInput() 

  const Card = useCardApi()
  const { add } = useCards()

  const navigate = useNavigate()

  const partners = useSelector(partnersSelectors.filter())
  const partnersOption = partners.map((partner) => ({ value: partner._id, label: partner.name }))

  const [partner, setPartner] = useState(null)

  
  const createHeandler = async () => {
    const card = await Card.create(number.value, partner, description.value)
    if(!card) { return }

    add(card)

    number.clear() 
    description.clear()

    pushMess('Successeful')

    navigate('/pool')
  } 

  return (
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.label}>Card</div>
        <Input input={number} placeholder='Card' />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Card</div>
        <Select option={partnersOption} handler={(option) => { setPartner(option.value) }} placeholder={'Select partner'} />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Description</div>
        <Input input={description} placeholder='Description' />
      </div>
      <div className={styles.buttons}>
        <button className={`${styles.button} ${styles.button_main}`} onClick={() => createHeandler()}>Push</button>
        <Link className={styles.button} to={'/pool'}>Cancel</Link>
      </div>
    </div>
  )
}

export default CreateCard