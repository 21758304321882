import { LOAD, CLEAR, ADD, REFRESH, REMOVE } from './types/cards.types'

const initialState = {
    list: []
}

export default function cardsReducer(state=initialState, action) {
    switch(action.type) {
    case LOAD:
        return {...action.payload}
    case ADD:
        return {list: [action.payload, ...state.list]}
    case REFRESH:
        return {list: state.list.map((card) => {
            if(card._id === action.payload._id) { return action.payload }
            return card
        })}
    case REMOVE:
        return {list: state.list.filter((card) => (card._id !== action.payload.id))}
    case CLEAR:
        return {...initialState}
    default:
        return state
    }
}