import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import useInput from '../../hooks/input.hook'
import useFilter from '../../hooks/filter.hook'

import Input from '../../components/UI/Input'
import Filter from '../../components/Filter'
import Amount from '../../components/UI/Amount'
import ClientId from '../../components/UI/ClientId'
import CardSelect from '../../components/CardSelect'
import PartnerSelect from '../../components/PartnerSelect'
import WorkerSelect from '../../components/WorkerSelect'

import * as filterSelectors from '../../redux/selectors/filter.selectors'
import Select from '../UI/Select'

const statusOption = [
    {value: 'WAIT', label: 'WAIT'},
    {value: 'CONFIRM', label: 'CONFIRM'}, 
    {value: 'REJECT', label: 'REJECT'}, 
    {value: null, label: 'All'}
]

function InvoiceFilter() {
    const { setInvoicesFilter } = useFilter()

    const filter = useSelector(filterSelectors.invoice)
    const id = useInput(filter.id, (value) => setInvoicesFilter({id: value}))
    const amount = { min: filter.amount.min, max: filter.amount.max, client: filter.clientId }

    const filterOption = [
        { title: 'Status', elem: <Select option={statusOption} value={filter.status} handler={(option) => setInvoicesFilter({status: option.value})} placeholder="Status" /> },
        { title: 'Partner', elem: <PartnerSelect value={filter.partner} handler={(option) => setInvoicesFilter({partner: option.value})} define={true} /> },
        { title: 'Worker', elem: <WorkerSelect value={filter.worker} handler={(option) => setInvoicesFilter({worker: option.value})} /> },
        { title: 'Client', elem: <ClientId handler={(value) => setInvoicesFilter({clientId: value})} value={filter.clientId}/> },
        { title: 'Card', elem: <CardSelect value={filter.card} handler={(values) => setInvoicesFilter({card: values})} /> },
        { title: 'Amount', elem: <Amount handler={(value) => setInvoicesFilter({amount: value})} value={amount} /> }
    ]

    return (
        <>           
            <Filter option={filterOption} />
            <Input input={id} placeholder='id' />
        </>
    )
}

export default InvoiceFilter