import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useInvoiceApi from '../../API/invoice.api'
import useAdminApi from '../../API/admin.api'
import usePage from '../../hooks/page.hook'

import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/invoices.module.css'
import Invoice from '../../components/Admin/Invoice'
import InvoiceFilter from '../../components/Admin/InvoiceFilter'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'
import * as authSelectors from '../../redux/selectors/auth.selectors'


function Invoices() {
  const access = useSelector(authSelectors.adminAccess)
  const reportAccess = (access === 'ADMIN' || access === 'FIN')

  const adminApi = useAdminApi()
  const invoiceApi = useInvoiceApi()
  
  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.invoiceTriger)

  const [invoices, setInvoices] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [isClient, setIsClient] = useState(false)

  const range = useRange()

  const downloadReport = async () => { 
    const { start, stop } = range.value
    const url = await adminApi.invoiceReport(start, stop, isUpdate, isClient)
    window.open(url) 
  } 

  const load = async () => {
    const {list, count} = await invoiceApi.listAll(page, pagination.limit)

    pagination.setCount(count)
    setInvoices(list)
  }

  useEffect(() => { load() }, [page, triger])

  return (
    <div>
      {reportAccess &&
        <div className="d-flex">
          <Range range={range.bind} />
          <div>
            <input type="checkbox" checked={isUpdate} onChange={() => setIsUpdate((prev) => !prev)}/>
            <span>Udate At</span>
          </div>
          <div>
            <input type="checkbox" checked={isClient} onChange={() => setIsClient((prev) => !prev)}/>
            <span>Client Id</span>
          </div>
          <button className='report' onClick={downloadReport}>Report</button>
        </div>
      }
      <div className={styles.main}>
          <div className={styles.top}>
            <InvoiceFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card</div>
              <div>Worker</div>
              <div>Partner</div>
              <div>Client Id</div>              
              <div>ID</div>              
              <div className={styles.status}>Status</div>
              <div className={styles.status}>Forse</div>
              <div className={styles.date}>time</div>
          </div>

          <div className={styles.table}>
              {invoices.map((invoice) => <Invoice invoice={invoice} refresh={(call) => load().then(call)} key={invoice._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}


export default Invoices