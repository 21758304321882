import useApi from '../hooks/api.hook'
import useConnect from '../hooks/connect.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'

export default function usePaymentApi() {
    const { protectedRequest } = useApi()
    const getConnect = useConnect()

    const filter = useSelector(filterSelectors.payment)


    const list = async (page=1, limit=20) => {
        try { return await protectedRequest('api/payment/worker-list', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const listAll = async (page=1, limit=20) => {
        try { return await protectedRequest('api/payment/list-all', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const confirm = async (id) => {
        try { return await protectedRequest('api/payment/confirm', {payment: id}) }
        catch(error) { return [] } 
    }

    const reject = async (id) => {
        try { return await protectedRequest('api/payment/reject', {payment: id}) }
        catch(error) { return [] } 
    }

    const onUpdate = (handler) => {
        try { return getConnect('api/payment/connect', handler) }
        catch(error) { return false } 
    }

    return { 
        list,
        listAll,
        confirm,
        reject,
        onUpdate
    }
}