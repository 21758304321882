import useApi from '../hooks/api.hook'
import useConnect from '../hooks/connect.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'


export default function useInvoiceApi() {
    const { protectedRequest } = useApi()
    const getConnect = useConnect()

    const filter = useSelector(filterSelectors.invoice)


    const list = async (page=1, limit=20) => {
        try { return await protectedRequest('api/invoice/worker-list', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const listAll = async (page=1, limit=20) => {
        try { return await protectedRequest('api/invoice/list-all', {filter, page, limit}) }
        catch(error) { return {list: [], count: 0} } 
    }

    const confirm = async (id) => {
        try { return await protectedRequest('api/invoice/confirm', {invoice: id}) }
        catch(error) { return false } 
    }

    const unknow = async (card, amount, time, description) => {
        try { return await protectedRequest('api/unknow/create', {card, amount, time, description}) }
        catch(error) { return false } 
    }

    const onUpdate = (handler) => {
        try { return getConnect('api/invoice/connect', handler) }
        catch(error) { return false } 
    }

    return { 
        list,
        confirm,
        onUpdate,
        unknow,
        listAll
    }
}