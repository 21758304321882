import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/pages/unknows.module.css'
import useUnknowApi from '../API/unknow.api'
import usePage from '../hooks/page.hook'
import { useSelector } from 'react-redux'
import * as filterSelectors from '../redux/selectors/filter.selectors'
import Unknow from '../components/Unknow'
import UnknowFilter from '../components/UnknowFilter'


function Unknows() {
  const unknowApi = useUnknowApi() 
  
  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.unknowTriger)

  const [unknows, setUnknows] = useState([])
  
  const load = async () => {
    const {list, count} = await unknowApi.list(page, pagination.limit)

    pagination.setCount(count)
    setUnknows(list)
  }

  useEffect(() => { load() }, [page, triger])

  return (
    <div>
      <Link to='/unknow' className={styles.unknow}>Unauthorized transaction</Link>

      <div className={styles.main}>
          <div className={styles.top}>
            <UnknowFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card</div>
              <div>ID</div>
              <div className={styles.date}>time</div>
          </div>

          <div className={styles.table}>
              {unknows.map((unknow) => <Unknow unknow={unknow} refresh={() => load()} key={unknow._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}

export default Unknows