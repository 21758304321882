import React from 'react'
import styles from '../styles/sections/menu.module.css'
import * as icons from '../components/source/Icons.source'
import { NavLink } from 'react-router-dom'
import useAuth from '../hooks/auth.hook'


function SupportMenu() {
    const { logout } = useAuth()

    return (
        <div className={styles.main}>
            <div className={styles.menu}>
                <NavLink to={'./invoices'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Pay-in
                    </div>
                </NavLink>
                <NavLink to={'./payments'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Pay-out
                    </div>
                </NavLink>
                <NavLink to={'./pool'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Pool card
                    </div>
                </NavLink>
            </div>
            <div className={styles.logout}>
                <div className={styles.item} onClick={logout}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SupportMenu