import React from 'react'
import Select from './UI/Select'
import { useSelector } from 'react-redux'

import * as partnerSelectors from '../redux/selectors/partners.selectors'

function PartnerSelect({handler, value, define=false}) {

    const options = useSelector(partnerSelectors.options) || []
    if(define) { options.splice(0, 1) }
    
    return (
        <Select option={options} value={value} handler={handler} placeholder={'Select partner'} />
    )
}

export default PartnerSelect