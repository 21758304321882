import React from 'react'
import Select from './UI/Select'
import { useSelector } from 'react-redux'

import * as cardsSelectors from '../redux/selectors/cards.selectors'
import MultiSelect from './UI/MultiSelect'


function CardSelect({handler, value}) {
    const options = useSelector(cardsSelectors.options) || []

    return (
        // <Select option={options} value={value} handler={handler}  placeholder={'Select card'} />
        <MultiSelect option={options} value={value} handler={handler}  placeholder={'Select card'} />
    )
}

export default CardSelect