import { useDispatch } from 'react-redux'

import useCardApi from '../API/card.api'

import * as cards from '../redux/actions/cards.actions'


export default function useCards() {
    const CardApi = useCardApi()
    const dispath = useDispatch()

    const load = async () => { dispath(cards.load(await CardApi.list())) }
    const refresh = async (card) => { dispath(cards.refresh(card)) }
    const setPartner = async (id, partner) => { dispath(cards.refresh(await CardApi.setPartner(id, partner))) }
    const turn = async (id) => { dispath(cards.refresh(await CardApi.turn(id))) }
    const turnWatch = async (id) => { dispath(cards.refresh(await CardApi.turnWatch(id))) }
    const remove = async (id) => { if(await CardApi.remove(id)) { dispath(cards.remove(id)) } }
    const add = (card) => { dispath(cards.add(card)) }
    const clear = () => { dispath(cards.clear()) }

    // Admins 

    const loadAll = async () => { dispath(cards.load(await CardApi.listAll())) }

    return { 
        load, 
        setPartner,
        refresh,
        turn,
        turnWatch,
        remove,
        add,
        clear,
        loadAll  
    }
}