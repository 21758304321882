import React from 'react'
import { useSelector } from 'react-redux'
import useInput from '../../hooks/input.hook'
import useFilter from '../../hooks/filter.hook'

import Input from '../../components/UI/Input'
import Filter from '../../components/Filter'
import Amount from '../../components/UI/Amount'
import CardSelect from '../../components/CardSelect'
import PartnerSelect from '../../components/PartnerSelect'
import WorkerSelect from '../../components/WorkerSelect'

import * as filterSelectors from '../../redux/selectors/filter.selectors'


function UnknowFilter() {
    const { setUnknowsFilter } = useFilter()

    const filter = useSelector(filterSelectors.unknow)

    const id = useInput(filter.id, (value) => setUnknowsFilter({id: value}))
    const amount = { min: filter.amount.min, max: filter.amount.max }

    const filterOption = [
        { title: 'Partner', elem: <PartnerSelect value={filter.partner} handler={(option) => setUnknowsFilter({partner: option.value})} /> },
        { title: 'Worker', elem: <WorkerSelect value={filter.worker} handler={(option) => setUnknowsFilter({worker: option.value})} /> },
        { title: 'Card', elem: <CardSelect value={filter.card} handler={(values) => setUnknowsFilter({card: values})}/> },
        { title: 'Amount', elem: <Amount handler={(value) => setUnknowsFilter({amount: value})} value={amount}/> }
    ]

    return (
        <>
            <Filter option={filterOption} />
            <Input input={id} placeholder='id' />
        </>
    )
}

export default UnknowFilter