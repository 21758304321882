import { combineReducers } from 'redux'
import authRuducer from './authRuducer'
import userReducer from './userReducer'
import alertReducer from './alertRuducer'
import cardsReducer from './cardsReducer'
import partnersReducer from './partnersReducer'
import workersReducer from './workersReducer'
import filterReducer from './filterReducer'


const rootReducer = combineReducers({
    auth: authRuducer,
    user: userReducer,
    cards: cardsReducer,
    partners: partnersReducer,
    workers: workersReducer,
    alert: alertReducer,

    filter: filterReducer
})


export default rootReducer