import React from 'react'
import Select from './UI/Select'
import { useSelector } from 'react-redux'

import * as workersSelectors from '../redux/selectors/workers.selectors'


function WorkerSelect({handler, value}) {
    const options = useSelector(workersSelectors.options) || []

    return (
        <Select option={options} value={value} handler={handler} placeholder={'Select worker'} />
    )
}

export default WorkerSelect