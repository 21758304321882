import React, { useState } from 'react'
import { formatTime } from '../../utils/time.utils'
import { useSelector } from 'react-redux'

import styles from '../../styles/components/admin/invoice.module.css'
import Input from '../UI/Input'
import useInput from '../../hooks/input.hook'
import * as icons from '../source/Icons.source'
import useAdminApi from '../../API/admin.api'
import * as authSelectors from '../../redux/selectors/auth.selectors'


function Invoice({invoice, refresh=()=>{}}) {
    const access = useSelector(authSelectors.adminAccess)
    const editAccess = (access === 'ADMIN' || access === 'SUPPORT')

    const amount = useInput(invoice?.amount)
    const adminApi = useAdminApi()
    const [isWait, setIsWait] = useState(false)

    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [isOpenReject, setIsOpenReject] = useState(false)

    const forseConfirm = async () => {
        if(!isOpenConfirm) { return setIsOpenConfirm(true) }

        setIsWait(true)
        await adminApi.forseConfirm(invoice._id, amount.value)
        refresh(() => setIsWait(false))
        setIsOpenConfirm(false)
    }

    const forseReject = async () => {
        if(!isOpenReject) { return setIsOpenReject(true) }

        setIsWait(true)
        await adminApi.forseReject(invoice._id)
        refresh(() => setIsWait(false))
        setIsOpenReject(false)
    }

    const confirmEmergent = async () => {
        setIsWait(true)
        await adminApi.confirmEmergent(invoice._id)
        refresh(() => setIsWait(false))
    }

    const rejectEmergent = async () => {
        setIsWait(true)
        await adminApi.rejectEmergent(invoice._id)
        refresh(() => setIsWait(false))
    }

    return (
        <div className={styles.main}>
            <div>{invoice?.emergent && "IS TECH"} {invoice?.amount} ₴</div>
            <div>{invoice?.cardNumber}</div>
            <div>{invoice?.workerName || invoice?.worker}</div>
            <div>{invoice?.partnerName || invoice?.partner}</div>
            <div>{invoice?.clientId}</div>
            <div>{invoice?._id}</div>
            <div className={`${styles.status} ${invoice?.status}`}>{invoice?.status}</div>

            <div>
                {editAccess && <>
                    {invoice?.status === 'REJECT' && !isWait && <>
                        <Input input={amount} className={styles.input}/>
                        <button className={`${styles.button} ${styles.forse_button}`} onClick={() => forseConfirm()}>
                            {icons.check}
                        </button>
                    </>}

                    {invoice?.status === 'CONFIRM' && !isWait && <>
                        <button className={`${styles.button} ${styles.forse_button}`} onClick={() => forseReject()}>
                            {icons.dismis} 
                        </button>
                    </>}

                    {invoice?.status === 'WAIT' && invoice.emergent && !isWait && <>
                        <button className={`${styles.button} ${styles.forse_button}`} onClick={() => confirmEmergent()}>
                            {icons.check}
                        </button>
                        <button className={`${styles.button} ${styles.forse_button}`} onClick={() => rejectEmergent()}>
                            {icons.dismis}
                        </button>
                    </>}
                </>}
            </div>

            <div className={styles.date}>
                {formatTime(invoice?.createdAt).replaceAll(',', " ")}
            </div>
        </div>
      )
}

export default Invoice