import React, { useEffect } from 'react'
import styles from '../../styles/components/amount.module.css'
import Input from './Input'
import useInput from '../../hooks/input.hook'


function Amount({handler, value={min: '', max: ''}}) {
    const minAmount = useInput(value.min)
    const maxAmount = useInput(value.max)

    useEffect(() => {
        handler({
            min: minAmount.value,
            max: maxAmount.value
        })
    }, [minAmount.value, maxAmount.value])
    
    return (
        <div className={styles.main}>
            <Input input={minAmount} placeholder='min' />
            <span>-</span>
            <Input input={maxAmount} placeholder='max' />
        </div>
    )
}

export default Amount