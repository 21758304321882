import React, { useState } from 'react'
import styles from '../styles/pages/auth.module.css'
import useInput from '../hooks/input.hook'
import Input from '../components/UI/Input'
import useWorkerApi from '../API/worker.api'
import useAuth from '../hooks/auth.hook'


function Auth() {
  const [twoFA, setTwoFA] = useState(false)

  const login = useInput() 
  const password = useInput() 
  const code = useInput()

  const Worker = useWorkerApi()
  const auth = useAuth()

  const twoFAHandler = async () => { setTwoFA(await Worker.twoFA(login.value, password.value)) }
  
  const loginHandler = async () => { 
    const data = await Worker.verify(login.value, password.value, code.value)
    if(!data) { 
      code.clear()
      return setTwoFA(false) 
    }

    const {token, workerId} = data
    auth.login(token, workerId)
  }


  return (
    <>
      {twoFA? 
        <div className='card'>
          <div className={styles.main}>
            <h2 className={styles.title}>2FA</h2>
  
            <Input input={code} placeholder='Code' />
            <button className='button' onClick={loginHandler}>Login</button>
          </div>
        </div>
      : 
        <div className='card'>
          <div className={styles.main}>
            <h2 className={styles.title}>Login</h2>

            <Input input={login} placeholder='Login' />
            <Input input={password} type='password' placeholder='password' />

            <button className='button' onClick={() => twoFAHandler()}>Login</button>
          </div>
        </div>
      }
    </>    
  )
}


export default Auth