import React from 'react'
import styles from '../styles/sections/menu.module.css'
import * as icons from '../components/source/Icons.source'
import { NavLink } from 'react-router-dom'
import useAuth from '../hooks/auth.hook'
import { useSelector } from 'react-redux'
import * as selectors from '../redux/selectors/user.selectors'


function Menu() {
    const { logout } = useAuth()
    const balance = useSelector(selectors.balance)
    const waitBalance = useSelector(selectors.waitBalance)

    return (
        <div className={styles.main}>
            <div className={styles.balance}>
                <div className={styles.icon}>
                    {icons.home}
                </div>
                <div className={styles.value}>
                    <p>{balance} UAH</p>
                    <p>({waitBalance}) UAH</p>
                </div>
            </div>
            <div className={styles.menu}>
                <NavLink to={'./statistic'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.profile}
                    </div>
                    <div className={styles.value}>
                        Dashboard
                    </div>
                </NavLink>
                <NavLink to={'./invoices'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Pay-in
                    </div>
                </NavLink>
                <NavLink to={'./payments'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowLeft}
                    </div>
                    <div className={styles.value}>
                        Pay-out
                    </div>
                </NavLink>
                <NavLink to={'./unknows'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Unknows
                    </div>
                </NavLink>
                <NavLink to={'./withdraw'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.arrowRigth}
                    </div>
                    <div className={styles.value}>
                        Withdraw
                    </div>
                </NavLink>
                <NavLink to={'./pool'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Pool card
                    </div>
                </NavLink>
                <NavLink to={'./monos'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Tokens
                    </div>
                </NavLink>
                <NavLink to={'./settings'} className={styles.item}>
                    <div className={styles.icon}>
                        {icons.profile}
                    </div>
                    <div className={styles.value}>
                        Account
                    </div>
                </NavLink>
            </div>
            <div className={styles.logout}>
                <div className={styles.item} onClick={logout}>
                    <div className={styles.icon}>
                        {icons.card}
                    </div>
                    <div className={styles.value}>
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Menu