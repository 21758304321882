import React from 'react'
import styles from '../../styles/components/admin/card.module.css'
import { formatTime } from '../../utils/time.utils'

function Card({card, conversion}) {  
  const cardStatus = card?.isLim? 'Blocked' : card?.active? 'Active' : 'Inactive'
  const cardStatusStyle = (card?.isLim || !card?.active)? styles.inactive : styles.active

  return (
    <div className={styles.card}>
        <div>{card?.number}</div>
        <div>{card?.description}</div>
        <div>
          {card?.limits?.min}/{card?.limits?.max}
          <br/>
          {card?.count}/{card?.limits?.count}
          <br/>
          {parseInt(card?.total)}/{card?.limits?.total}
        </div>
        <div>{card?.partnerName}</div>
        <div>{card?.workerName}</div>
        <div>{card?._id}</div>
        <div>
            <div className={`${styles.status} ${cardStatusStyle}`}>
              {cardStatus}
            </div>
        </div>
        <div className={styles.conv}>
          {(conversion && conversion.all[card?._id]) && <div>
            <span style={{color: '#AAD6A9'}}>{conversion.all[card._id].countConfirm}/</span>
            <span style={{color: '#E25D4A'}}>{conversion.all[card._id].count}</span>
            <span>({parseInt(100 * conversion.all[card._id].conversion)}%)</span>
          </div>} 
          {(conversion && conversion.temp[card?._id]) && <div>
            <span style={{color: '#AAD6A9'}}>{conversion.temp[card._id].countConfirm}/</span>
            <span style={{color: '#E25D4A'}}>{conversion.temp[card._id].count}</span>
            <span>({parseInt(100 * conversion.temp[card._id].conversion)}%)</span>
          </div>} 
        </div>
        <div className={styles.date}>{formatTime(card?.createdAt).replaceAll(',', " ")}</div>
    </div>
  )
}

export default Card