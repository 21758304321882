import useApi from '../hooks/api.hook'


export default function useMonoApi() {
    const { protectedRequest } = useApi()

    const create = async (token, description) => {
        try { return await protectedRequest('api/mono/create', {token, description}) }
        catch(error) { return null } 
    }

    const sync = async (id) => {
        try { return await protectedRequest('api/mono/sync', {id}) }
        catch(error) { return false } 
    }

    const report = async (id, start, stop) => {
        try { return await protectedRequest('api/mono/report', {id, start, stop}) }
        catch(error) { return false } 
    }

    const remove = async (id) => {
        try { return await protectedRequest('api/mono/remove', {id}) }
        catch(error) { return false } 
    }

    const list = async (page, limit) => {
        try { return await protectedRequest('api/mono/list', {page, limit}) }
        catch(error) { return { list: [], count: 0 } } 
    }

    return { 
        create,
        sync,
        report,
        remove,
        list
    }
}