import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useAdminApi from '../../API/admin.api'
import useUnknowApi from '../../API/unknow.api'
import usePage from '../../hooks/page.hook'

import Unknow from '../../components/Admin/Unknow'
import UnknowFilter from '../../components/Admin/UnknowFilter'

import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/unknows.module.css'
import Range from '../../components/Range'
import useRange from '../../hooks/range.hook'


function Unknows() {
  const adminApi = useAdminApi()
  const unknowApi = useUnknowApi() 

  const pagination = usePage(30)
  const page = pagination.page

  const triger = useSelector(filterSelectors.unknowTriger)

  const [unknows, setUnknows] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)

  const range = useRange()

  const downloadReport = async () => { 
    const { start, stop } = range.value
    const url = await adminApi.unknowReport(start, stop, isUpdate)
    window.open(url) 
  }

  const load = async () => {
    const {list, count} = await unknowApi.listAll(page, pagination.limit)

    pagination.setCount(count)
    setUnknows(list)
  }

  useEffect(() => { load() }, [page, triger])

  return (
    <div>
      <div className='d-flex'>
        <Range range={range.bind} />
        <div>
          <input type="checkbox" checked={isUpdate} onChange={() => setIsUpdate((prev) => !prev)}/>
          <span>Udate At</span>
        </div>
        <button className='report' onClick={downloadReport}>Report</button>
      </div>

      <div className={styles.main}>
          <div className={styles.top}>
            <UnknowFilter />
          </div>

          <div className={styles.info}>
              <div>Amount</div>
              <div>Card</div>
              <div>Worker</div>
              <div>Partner</div>
              <div>ID</div>
              <div className={styles.date}>time</div>
          </div>

          <div className={styles.table}>
              {unknows.map((unknow) => <Unknow unknow={unknow} refresh={() => load()} key={unknow._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}

export default Unknows