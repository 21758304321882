import React from 'react'
import { formatTime } from '../../utils/time.utils'

import styles from '../../styles/components/admin/withdraw.module.css'

function Withdraw({withdraw}) {
    return (
        <div className={styles.main}>
            <div>{withdraw?.amount} ₴</div>
            <div>{withdraw?.course}</div>
            <div>{withdraw?.workerName || withdraw?.worker}</div>
            <div>{withdraw?.partnerName || withdraw?.partner}</div>
            <div>{withdraw?._id}</div>
            <div className={styles.date}>
                {formatTime(withdraw?.createdAt).replaceAll(',', " ")}
            </div>
        </div>
      )
}

export default Withdraw