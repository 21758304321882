import React, { useEffect, useMemo, useState } from 'react'
import usePage from '../../hooks/page.hook'
import { useSelector } from 'react-redux'
import usePartners from '../../hooks/partners.hook'
import * as partnersSelectors from '../../redux/selectors/partners.selectors'
import * as filterSelectors from '../../redux/selectors/filter.selectors'

import styles from '../../styles/pages/admin/partners.module.css'
import Partner from '../../components/Admin/Partner'
import PartnerFilter from '../../components/Admin/PartnersFilter'


function Partners() {  
  const partnersList = useSelector(partnersSelectors.filter())
  const count = useMemo(() => partnersList.length, [partnersList])

  const { load } = usePartners()

  const [partners, setPartners] = useState([])
  const [isLoad, setIsLoad] = useState(false)

  const pagination = usePage(30, count)
  const page = pagination.page

  const triger = useSelector(filterSelectors.partnerTriger)

  useEffect(() => { (async () => load())().then(() => setIsLoad(true)) }, [])

  useEffect(() => {
    const { begin, end } = pagination.getRange()

    setPartners(partnersList.slice(begin, end))
  }, [page, triger, isLoad])

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.top}>
          <PartnerFilter />
        </div>

        <div className={styles.info}>
            <div>Name</div>
            <div>ID</div>              
            <div className={styles.date}>Balance</div>
        </div>

        <div className={styles.table}>
            {partners.map((partner) => <Partner partner={partner} key={partner._id} />)}
        </div>

        <div className={styles.bottom}>
            <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                Previos
            </button>
            <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                Next
            </button>
        </div>
      </div>
    </div>
  )
}

export default Partners