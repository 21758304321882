import React, { useEffect, useState } from 'react'

import usePage from '../hooks/page.hook'

import styles from '../styles/pages/monos.module.css'
import useMonoApi from '../API/mono.api'
import Mono from '../components/Mono'
import { Link } from 'react-router-dom'
import useRange from '../hooks/range.hook'
import Range from '../components/Range'
import useAlert from '../hooks/alert.hook'


function Monos() {
  const monoApi = useMonoApi()
  const { pushMess } = useAlert()
  
  const pagination = usePage(30)
  const page = pagination.page

  const [monos, setMonos] = useState([])

  const range = useRange()

  const load = async () => {
    const {list, count} = await monoApi.list(page, pagination.limit)

    pagination.setCount(count)
    setMonos(list)
  }

  const reportHandler = async (id) => {
    const { start, stop } = range.value
    monoApi.report(id, start, stop).then()
    pushMess('Please wait. The reports will be send to TG. Dont click again.')
  }

  useEffect(() => { load() }, [page])

  return (
    <div>
      <div>
        <Link to='/mono' className={styles.unknow}>New token</Link>
        <Range range={range.bind} />
        <br />
      </div>

      <div className={styles.main}>
          <div className={styles.info}>
              <div>Description</div>
              <div>Id</div>
              <div>Action</div>
              <div>Status</div>
          </div>

          <div className={styles.table}>
              {monos.map((mono) => <Mono mono={mono} reload={() => load()} reportHandler={reportHandler} key={mono._id} />)}
          </div>

          <div className={styles.bottom}>
              <button className={`${styles.button} ${!pagination.isBack? styles.diactive : null}`} onClick={pagination.back}>
                  Previos
              </button>
              <button className={`${styles.button} ${!pagination.isNext? styles.diactive : null}`} onClick={pagination.next}>
                  Next
              </button>
          </div>
      </div>
    </div>
  )
}


export default Monos