export const SET_UNKNOWS = 'FILTER:UNKNOWS:SET'
export const CLEAR_UNKNOWS = 'FILTER:UNKNOWS:CLEAR'

export const SET_PAYMENTS = 'FILTER:PAYMENTS:SET'
export const CLEAR_PAYMENTS = 'FILTER:PAYMENTS:CLEAR'

export const SET_INVOICES = 'FILTER:INVOICES:SET'
export const CLEAR_INVOICES = 'FILTER:INVOICES:CLEAR'

export const SET_CARDS = 'FILTER:CARDS:SET'
export const CLEAR_CARDS = 'FILTER:CARDS:CLEAR'

export const SET_PARTNERS = 'FILTER:PARTNERS:SET'
export const CLEAR_PARTNERS = 'FILTER:PARTNERS:CLEAR'

export const SET_WORKERS = 'FILTER:WORKERS:SET'
export const CLEAR_WORKERS = 'FILTER:WORKERS:CLEAR'

export const SET_WITHDRAWS = 'FILTER:WITHDRAWS:SET'
export const CLEAR_WITHDRAWS = 'FILTER:WITHDRAWS:CLEAR'